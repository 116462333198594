import { AnimatePresence, motion } from 'framer-motion';
import { Repeat } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Beacon } from '../../../App';
import { availableBeaconConfigs } from '../../../const';
import { allKPopArtists, groupImages } from '../../../kpop';
import styles from '../../DeviceForm.module.css';
import MacAddressInput from '../MacAddressInput';

const pickedArtists = new Set<string>();

interface BeaconBasicSectionProps {
  data: Pick<Beacon, 'name' | 'macAddress' | 'brand' | 'modelName' | 'linkedItemId'>;
  setData: (data: any) => void;
  allDevices: any[];
}

interface KpopArtist {
  'Stage Name': string;
  'Full Name': string;
  'Korean Name': string;
  'K Stage Name': string;
  'Date of Birth': string;
  Group: string;
  Debut: string;
  Company: string;
  Country: string;
  'Second Country': string;
  Height: string;
  Weight: string;
  Birthplace: string;
  'Other Group': string;
  'Former Group': string;
  Gender: string;
  'Image URL'?: string;
}

const BeaconBasicSection = ({ data, setData, allDevices }: BeaconBasicSectionProps) => {
  const [suggestedBeaconBrands, setSuggestedBeaconBrands] = useState<string[]>([]);
  const [suggestedBeaconModels, setSuggestedBeaconModels] = useState<string[]>([]);
  const [brandInput, setBrandInput] = useState(data.brand || '');
  const [modelInput, setModelInput] = useState(data.modelName || '');
  const [kpopArtist, setKpopArtist] = useState<KpopArtist | undefined>();

  useEffect(() => {
    const selectedBrandConfig = availableBeaconConfigs.find((config) => config.name.toLowerCase() === brandInput.toLowerCase());
    if (selectedBrandConfig?.beaconModels) {
      const models = selectedBrandConfig.beaconModels.map((model) => model.modelName);
      setSuggestedBeaconModels(models);
    } else {
      setSuggestedBeaconModels([]);
    }
  }, [brandInput]);

  const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBrandInput(value);
    setData({ ...data, brand: value });
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setModelInput(value);
    setData({ ...data, modelName: value });
  };

  const handleBrandSelect = (brand: string) => {
    setBrandInput(brand);
    setData({ ...data, brand });
  };

  const handleModelSelect = (model: string) => {
    setModelInput(model);
    const brandModels = availableBeaconConfigs.find((config) => config.name.toLowerCase() === data.brand.toLowerCase())?.beaconModels;
    const selectedModel = brandModels?.find((config) => config.modelName.toLowerCase() === model.toLowerCase());

    if (selectedModel) {
      setData({ ...data, ...selectedModel, brand: brandInput });
    } else {
      setData({ ...data, modelName: model });
    }
  };

  const pickRandomKpopArtist = () => {
    if (!allDevices || allDevices.length < 1) return;

    const allDevicesNames = allDevices.map((device) => device.name);
    const kpopArtists = allKPopArtists.filter((artist) => {
      const availableGroupImages = groupImages.map((group) => group.name.toLowerCase());
      return availableGroupImages.includes(artist.Group.toLowerCase());
    });

    const filteredKpopArtists = kpopArtists.filter((artist) => !allDevicesNames.includes(artist['Stage Name']) && !pickedArtists.has(artist['Stage Name']));

    if (filteredKpopArtists.length === 0) {
      pickedArtists.clear();
      filteredKpopArtists.push(...kpopArtists.filter((artist) => !allDevicesNames.includes(artist['Stage Name'])));
    }

    const randomIndex = Math.floor(Math.random() * filteredKpopArtists.length);
    const randomArtist = filteredKpopArtists[randomIndex] as KpopArtist;

    pickedArtists.add(randomArtist['Stage Name']);
    setData({ ...data, name: randomArtist['Stage Name'] });
    setKpopArtist(randomArtist);
  };

  useEffect(() => {
    setBrandInput(data.brand);
    setModelInput(data.modelName);

    const brands = availableBeaconConfigs.map((config) => config.name);
    setSuggestedBeaconBrands(brands);

    const matchedKpopArtist = allKPopArtists.find((artist) => artist['Stage Name'].toLowerCase() === data.name.toLowerCase()) as KpopArtist | undefined;
    if (matchedKpopArtist) {
      setKpopArtist(matchedKpopArtist);
    }
  }, []);

  return (
    <div className={styles.formSection}>
      <div className={styles.formGrid}>
        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Name
            <input type="text" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} className={styles.input} placeholder="MeiiMeii" required />
            <button type="button" onClick={pickRandomKpopArtist} className={styles.qrScanButton} style={{ marginTop: '8px' }} title="Random K-pop Artist">
              <Repeat size={20} />
            </button>
          </label>
        </div>

        <AnimatePresence mode="wait">
          <motion.div key={kpopArtist?.['Stage Name']} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.2 }} className={styles.formField}>
            {kpopArtist && (
              <div className={styles.kpopArtistCard}>
                <div className={styles.kpopArtistImg}>
                  <img src={kpopArtist['Image URL'] ? kpopArtist['Image URL'] : groupImages.find((group) => group.name.toLowerCase() === kpopArtist.Group.toLowerCase())?.imageUrl} alt={kpopArtist['Stage Name']} />
                </div>

                <div className={styles.kpopArtistSection}>
                  <h3>{kpopArtist['Stage Name']}</h3>
                  <p className={styles.fullName}>{kpopArtist['Full Name']}</p>
                  <p className={styles.koreanName}>{kpopArtist['Korean Name']}</p>
                  <p style={{ fontFamily: 'Geist Bold' }} className={styles.groupName}>
                    {kpopArtist['Group']}
                  </p>
                </div>

                <div className={styles.kpopArtistSection}>
                  <p>📏 {kpopArtist['Height']}</p>
                  <p>{kpopArtist['Weight']}</p>
                  <p>🐣 {kpopArtist['Debut']}</p>
                  <p>📍 {kpopArtist['Country']}</p>
                  <p>👼🏼 {kpopArtist['Birthplace']}</p>
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            MAC Address
            <MacAddressInput value={data.macAddress} onChange={(value: string) => setData({ ...data, macAddress: value })} className={styles.input} required />
          </label>
        </div>

        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Brand
            <input type="text" value={data.brand} onChange={handleBrandChange} className={styles.input} placeholder="Minew Tech" required />
            {suggestedBeaconBrands.length > 0 && (
              <div className={styles.suggestions}>
                {suggestedBeaconBrands
                  .filter((brand) => brand.toLowerCase().includes(brandInput.toLowerCase()))
                  .map((brand) => (
                    <p key={brand} onClick={() => handleBrandSelect(brand)}>
                      {brand}
                    </p>
                  ))}
              </div>
            )}
          </label>
        </div>

        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Model
            <input type="text" value={data.modelName} onChange={handleModelChange} required className={styles.input} placeholder="E2 - Beacon Plus-LB" />
            {suggestedBeaconModels && suggestedBeaconModels.length > 0 && (
              <div className={styles.suggestions}>
                {suggestedBeaconModels
                  .filter((model) => model.toLowerCase().includes(modelInput.toLowerCase()))
                  .map((model) => (
                    <p key={model} onClick={() => handleModelSelect(model)}>
                      {model}
                    </p>
                  ))}
              </div>
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default BeaconBasicSection;
