import { useEffect, useState } from 'react';
import { Gateway } from '../../../App';
import { availableGatewayConfigs } from '../../../const';
import styles from '../../DeviceForm.module.css';

interface GatewayDeviceInfoSectionProps {
  data: Pick<Gateway, 'brand' | 'modelName' | 'mcuModel'>;
  setData: (data: any) => void;
}

const GatewayDeviceInfoSection = ({ data, setData }: GatewayDeviceInfoSectionProps) => {
  const [suggestedGatewayBrands, setSuggestedGatewayBrands] = useState<string[]>([]);
  const [suggestedGatewayModels, setSuggestedGatewayModels] = useState<string[]>([]);
  const [brandInput, setBrandInput] = useState(data.brand || '');
  const [modelInput, setModelInput] = useState(data.modelName || '');

  useEffect(() => {
    const selectedBrandConfig = availableGatewayConfigs.find((config) => config.name.toLowerCase() === brandInput.toLowerCase());
    if (selectedBrandConfig?.gatewayModels) {
      const models = selectedBrandConfig.gatewayModels.map((model) => model.modelName);
      setSuggestedGatewayModels(models);
    } else {
      setSuggestedGatewayModels([]);
    }
  }, [brandInput]);

  const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBrandInput(value);
    setData({ ...data, brand: value });
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setModelInput(value);
    setData({ ...data, modelName: value });
  };

  const handleBrandSelect = (brand: string) => {
    setBrandInput(brand);
    setData({ ...data, brand });
  };

  const handleModelSelect = (model: string) => {
    setModelInput(model);
    const brandModels = availableGatewayConfigs.find((config) => config.name.toLowerCase() === brandInput.toLowerCase() && config.gatewayModels.some((m) => m.modelName.toLowerCase() === model.toLowerCase()));
    const selectedModel = brandModels?.gatewayModels.find((m) => m.modelName.toLowerCase() === model.toLowerCase());

    if (selectedModel) {
      setData({
        ...data,
        ...selectedModel,
        brand: brandInput
      });
    } else {
      setData({ ...data, modelName: model });
    }
  };

  useEffect(() => {
    setBrandInput(data.brand);
    setModelInput(data.modelName);

    const brands = availableGatewayConfigs.map((config) => config.name);
    setSuggestedGatewayBrands(brands);
  }, []);

  return (
    <div className={styles.formSection}>
      <div className={styles.formGrid}>
        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Brand
            <input type="text" value={data.brand} onChange={handleBrandChange} className={styles.input} placeholder="Minew Tech" required />
            {suggestedGatewayBrands.length > 0 && (
              <div className={styles.suggestions}>
                {suggestedGatewayBrands
                  .filter((brand) => brand.toLowerCase().includes(brandInput.toLowerCase()))
                  .map((brand) => (
                    <p key={brand} onClick={() => handleBrandSelect(brand)}>
                      {brand}
                    </p>
                  ))}
              </div>
            )}
          </label>
        </div>

        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Model
            <input type="text" value={data.modelName} onChange={handleModelChange} required className={styles.input} placeholder="E2 - Beacon Plus-LB" />
            {suggestedGatewayModels && suggestedGatewayModels.length > 0 && (
              <div className={styles.suggestions}>
                {suggestedGatewayModels
                  .filter((model) => model.toLowerCase().includes(modelInput.toLowerCase()))
                  .map((model) => (
                    <p key={model} onClick={() => handleModelSelect(model)}>
                      {model}
                    </p>
                  ))}
              </div>
            )}
          </label>
        </div>

        <div className={styles.formField}>
          <label className={styles.label}>
            MCU Model
            <input type="text" value={data.mcuModel} onChange={(e) => setData({ ...data, mcuModel: e.target.value })} className={styles.input} style={{ height: '20px' }} placeholder="ESP32" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default GatewayDeviceInfoSection;
