import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Auth from './Auth';
import BeaconMap from './BeaconMap';
import BeaconMapV2 from './BeaconMap/v2';
import DeviceDetails from './DeviceDetails';
import DeviceForm from './DeviceForm';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { UserProvider } from './UserContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/account',
    element: <Auth />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/device/:id',
    element: <DeviceDetails />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/device/:id/edit',
    element: <DeviceForm />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/device/new',
    element: <DeviceForm />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/beacons',
    element: <BeaconMap />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/beacons/v2',
    element: <BeaconMapV2 />,
    errorElement: <div>404 Not Found</div>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
