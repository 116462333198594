import { AnimatePresence, motion } from 'framer-motion';
import { Repeat } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Gateway } from '../../../App';
import { allKPopArtists, groupImages } from '../../../kpop';
import styles from '../../DeviceForm.module.css';
import MacAddressInput from '../MacAddressInput';

const pickedArtists = new Set<string>();

interface GatewayBasicSectionProps {
  data: Pick<Gateway, 'name' | 'macAddress' | 'linkedItemId'>;
  setData: (data: any) => void;
  allDevices: any[];
}

interface KpopArtist {
  'Stage Name': string;
  'Full Name': string;
  'Korean Name': string;
  'K Stage Name': string;
  'Date of Birth': string;
  Group: string;
  Debut: string;
  Company: string;
  Country: string;
  'Second Country': string;
  Height: string;
  Weight: string;
  Birthplace: string;
  'Other Group': string;
  'Former Group': string;
  Gender: string;
  'Image URL'?: string;
}

const GatewayBasicSection = ({ data, setData, allDevices }: GatewayBasicSectionProps) => {
  const [kpopArtist, setKpopArtist] = useState<KpopArtist | undefined>();

  const pickRandomKpopArtist = () => {
    if (!allDevices || allDevices.length < 1) return;

    const allDevicesNames = allDevices.map((device) => device.name);
    const kpopArtists = allKPopArtists.filter((artist) => {
      const availableGroupImages = groupImages.map((group) => group.name.toLowerCase());
      return availableGroupImages.includes(artist.Group.toLowerCase());
    });

    const filteredKpopArtists = kpopArtists.filter((artist) => !allDevicesNames.includes(artist['Stage Name']) && !pickedArtists.has(artist['Stage Name']));

    if (filteredKpopArtists.length === 0) {
      pickedArtists.clear();
      filteredKpopArtists.push(...kpopArtists.filter((artist) => !allDevicesNames.includes(artist['Stage Name'])));
    }

    const randomIndex = Math.floor(Math.random() * filteredKpopArtists.length);
    const randomArtist = filteredKpopArtists[randomIndex] as KpopArtist;

    pickedArtists.add(randomArtist['Stage Name']);
    setData({ ...data, name: randomArtist['Stage Name'] });
    setKpopArtist(randomArtist);
  };

  useEffect(() => {
    const matchedKpopArtist = allKPopArtists.find((artist) => artist['Stage Name'].toLowerCase() === data.name.toLowerCase()) as KpopArtist | undefined;
    if (matchedKpopArtist) {
      setKpopArtist(matchedKpopArtist);
    }
  }, []);

  return (
    <div className={styles.formSection}>
      <div className={styles.formGrid}>
        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Name
            <input type="text" value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} className={styles.input} placeholder="MeiiMeii" required />
            <button type="button" onClick={pickRandomKpopArtist} className={styles.qrScanButton} style={{ marginTop: '8px' }} title="Random K-pop Artist">
              <Repeat size={20} />
            </button>
          </label>
        </div>

        <AnimatePresence mode="wait">
          <motion.div key={kpopArtist?.['Stage Name']} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.2 }} className={styles.formField}>
            {kpopArtist && (
              <div className={styles.kpopArtistCard}>
                <div className={styles.kpopArtistImg}>
                  <img src={kpopArtist['Image URL'] ? kpopArtist['Image URL'] : groupImages.find((group) => group.name.toLowerCase() === kpopArtist.Group.toLowerCase())?.imageUrl} alt={kpopArtist['Stage Name']} />
                </div>

                <div className={styles.kpopArtistSection}>
                  <h3>{kpopArtist['Stage Name']}</h3>
                  <p>{kpopArtist['Full Name']}</p>
                  <p>{kpopArtist['Korean Name']}</p>
                  <p style={{ fontFamily: 'Geist Bold' }}>{kpopArtist['Group']}</p>
                </div>

                <div className={styles.kpopArtistSection}>
                  <p>📏 {kpopArtist['Height']}</p>
                  <p>{kpopArtist['Weight']}</p>
                  <p>🐣 {kpopArtist['Debut']}</p>
                  <p>📍 {kpopArtist['Country']}</p>
                  <p>👼🏼 {kpopArtist['Birthplace']}</p>
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            MAC Address
            <MacAddressInput value={data.macAddress} onChange={(value: string) => setData({ ...data, macAddress: value })} className={styles.input} required />
          </label>
        </div>

        <div className={styles.formField}>
          <label className={styles.label}>
            Linked Item
            <input type="text" value={data.linkedItemId} onChange={(e) => setData({ ...data, linkedItemId: e.target.value })} className={styles.input} style={{ height: '20px' }} placeholder="21456326546743" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default GatewayBasicSection;
