import { useCallback } from 'react';

/**
 * Hook for QR code download functionality
 * @param {string} elementId - The ID of the QR code SVG element
 * @param {string} fileName - The name for the downloaded file (without extension)
 * @returns {Function} Download function
 */
export const useQRCodeDownload = (elementId: string, fileName: string = 'qr-code'): (() => void) => {
  const downloadQRCode = useCallback(() => {
    const svg = document.getElementById(elementId);
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new Image();

    img.onload = () => {
      // Scale factor for higher quality PNG output
      const scale = 4;
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // Scale up the image for better quality
      ctx.scale(scale, scale);
      ctx.drawImage(img, 0, 0);

      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${fileName}.png`;
      downloadLink.href = pngFile;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  }, [elementId, fileName]);

  return downloadQRCode;
};

interface QRConfig {
  size: number;
  level: 'L' | 'M' | 'Q' | 'H';
  bgColor: string;
  fgColor: string;
  includeMargin: boolean;
}

/**
 * Default QR code configuration
 */
export const defaultQRConfig: QRConfig = {
  size: 256, // Increased from 80 to 256 for better quality
  level: 'M', // Highest error correction level for better reliability
  bgColor: 'transparent',
  includeMargin: true, // Added margin for better scanning
  fgColor: '#1a1a1a'
};

/**
 * Merges custom QR code config with defaults
 * @param {Partial<QRConfig>} customConfig - Custom configuration options
 * @returns {QRConfig} Merged configuration
 */
export const createQRConfig = (customConfig: Partial<QRConfig> = {}): QRConfig => ({
  ...defaultQRConfig,
  ...customConfig
});
