import jsQR from 'jsqr';
import { Camera, X } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../DeviceForm.module.css';

interface MacAddressInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  required?: boolean;
}

const MacAddressInput = ({ value, onChange, className, required = false }: MacAddressInputProps) => {
  const [isScanning, setIsScanning] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  const formatMacAddress = (input: string) => {
    const cleaned = input.replace(/[^0-9A-Fa-f]/g, '');
    const formatted = cleaned.match(/.{1,2}/g)?.join(':') || cleaned;
    return formatted.slice(0, 17).toUpperCase();
  };

  const stopScanning = useCallback(() => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setIsScanning(false);
  }, []);

  useEffect(() => {
    return () => {
      stopScanning();
    };
  }, [stopScanning]);

  const scanQRCode = useCallback(async () => {
    if (!videoRef.current || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext('2d', { willReadFrequently: true });
    if (!context) return;

    if (video.readyState !== video.HAVE_ENOUGH_DATA) {
      animationFrameRef.current = requestAnimationFrame(scanQRCode);
      return;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    try {
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        console.log('QR code detected:', code.data);
        const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        if (macAddressRegex.test(code.data)) {
          onChange(code.data);
          stopScanning();
          return;
        }

        const cleanedMac = formatMacAddress(code.data);
        if (cleanedMac.length === 17) {
          onChange(cleanedMac);
          stopScanning();
          return;
        }
      }
    } catch (error) {
      console.error('QR scanning error:', error);
    }

    if (isScanning) {
      animationFrameRef.current = requestAnimationFrame(scanQRCode);
    }
  }, [isScanning, onChange, stopScanning]);

  const handleQRScan = async () => {
    try {
      let stream;
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }
        });
      } catch {
        stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' }
        });
      }
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await new Promise((resolve) => {
          if (videoRef.current) {
            videoRef.current.onloadedmetadata = resolve;
          }
        });
        await videoRef.current.play();
        scanQRCode();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
      alert('Unable to access camera. Please check permissions.');
      setIsScanning(false);
    }
  };

  const startScanning = () => {
    setIsScanning(true);
  };

  useEffect(() => {
    if (isScanning) {
      handleQRScan();
    }
  }, [isScanning]);

  return (
    <div className={styles.macAddressInputWrapper} style={{ marginBottom: isScanning ? '350px' : '0px' }}>
      <input type="text" value={value} onChange={(e) => onChange(formatMacAddress(e.target.value))} className={`${className} ${styles.macAddressInput}`} placeholder="XX:XX:XX:XX:XX:XX" pattern="^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$" required={required} />
      <button type="button" onClick={startScanning} className={styles.qrScanButton} title="Scan QR Code">
        <Camera size={20} />
      </button>

      {isScanning && (
        <div className={styles.qrScannerModal}>
          <div className={styles.qrScannerContent}>
            <div className={styles.qrScannerHeader}>
              <h3>Scan QR Code</h3>
              <button type="button" onClick={stopScanning} className={styles.closeButton}>
                <X size={24} />
              </button>
            </div>

            <div className={styles.qrScannerViewfinder}>
              <video ref={videoRef} className={styles.qrVideo} playsInline muted autoPlay />
              <canvas ref={canvasRef} className={styles.qrCanvas} />
              <div className={styles.scannerOverlay}>
                <div className={styles.scannerTarget} />
              </div>
            </div>

            <p className={styles.qrScannerInstructions}>Position the QR code within the frame</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MacAddressInput;
