import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';
import styles from '../DeviceForm.module.css';

interface LinkedItemSectionProps {
  data: {
    linkedItemId?: string;
  };
  setData: (data: any) => void;
}

interface Item {
  id: string;
  name: string;
  description?: string;
  primaryImage?: string;
}

const LinkedItemSection = ({ data, setData }: LinkedItemSectionProps) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [allItems, setAllItems] = useState<Item[]>([]);
  const [results, setResults] = useState<Item[]>([]);

  const getAllItems = async () => {
    const response = await fetch('https://meii.minji.pack.wtf/api/items/all', {
      headers: {
        Authorization: `${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      const items = await response.json();
      const allItems = items.things.concat(items.bags);
      setAllItems(allItems);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllItems();
  }, []);

  const getResults = async (search: string) => {
    if (!allItems || allItems.length < 1) {
      setLoading(true);
      return;
    }
    const options = {
      keys: ['name', 'description'],
      includeScore: true
    };

    const fuse = new Fuse(allItems, options);
    const result = fuse.search(search);
    setResults(result.map((item) => item.item));
    setLoading(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setSearch(e.target.value);
    if (e.target.value === '') {
      setLoading(false);
    }
  };

  const searchRef = useRef(search);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (search === searchRef.current) {
        if (search === '') {
          setLoading(false);
          return;
        }
        getResults(search);
      }
    }, 1200);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  return (
    <div className={styles.formSection}>
      <div className={styles.formGrid}>
        <div className={styles.formField}>
          <label className={styles.label}>
            Linked Item
            <input type="text" value={data.linkedItemId} onChange={(e) => setData({ ...data, linkedItemId: e.target.value })} className={styles.input} placeholder="21456326546743" />
          </label>
        </div>
        <div className={styles.formField}>
          <label className={styles.label}>
            Search
            <input type="text" value={search} onChange={handleSearch} className={styles.input} placeholder="Search for item" />
          </label>

          {loading && <p>Loading...</p>}

          <div className={styles.suggestions}>
            {results.map((item) => (
              <div key={item.id} style={{ display: 'flex' }}>
                <img style={{ width: '50px', height: '50px', objectFit: 'cover' }} src={item.primaryImage} alt={item.name} />
                <p onClick={() => setData({ ...data, linkedItemId: item.id })}>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedItemSection;
