import { motion } from 'framer-motion';
import { ArrowLeft, Pencil, QrCode } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Device } from '../App';
import { useDevices } from '../hooks/useDevices';
import { createQRConfig, useQRCodeDownload } from '../hooks/useQRCode';
import { allKPopArtists, groupImages } from '../kpop';
import { Bag, Thing } from '../types';
import { DeviceHeader, LocationCard, QRCodeModal, StatsGrid } from './components';
import styles from './styles/Base.module.css';
import Timeline from './Timeline';

export type DeviceWithLogs = Device & { logs?: BeaconLog[] };

export interface BeaconLog {
  timestamp: number;
  beacon_id: string;
  beacon_name: string;
  receiver_id: string;
  receiver_name: string;
  uuid: string;
  major: number;
  minor: number;
  estimated_distance: number;
  raw_data: string;
  rssi: number;
  tx_power: number | null;
  estimated_proximity: string;
  mac_address: string;
  location_longitude?: number;
  location_latitude?: number;
  location_country?: string;
  location_name?: string;
  ip_address?: string;
  ip_country?: string;
}

const DeviceDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { devices, getDevice } = useDevices();
  const [deviceData, setDeviceData] = useState<DeviceWithLogs | null>(null);
  const [device, setDevice] = useState<Device | null>(null);
  const [showQrCode, setShowQrCode] = useState(false);

  const qrConfig = createQRConfig();
  const downloadQRCode = useQRCodeDownload('device-qr-code', device?.name || 'device');

  const [latestDeviceLog, setLatestDeviceLog] = useState<BeaconLog>({
    timestamp: 0,
    beacon_id: '',
    beacon_name: '',
    receiver_id: '',
    receiver_name: '',
    uuid: '',
    major: 0,
    minor: 0,
    estimated_distance: 0,
    raw_data: '',
    rssi: 0,
    tx_power: null,
    estimated_proximity: '',
    mac_address: ''
  });

  useEffect(() => {
    const device = devices.find((d) => d.deviceId === id);
    setDevice(device || null);
  }, [devices, id]);

  useEffect(() => {
    const fetchDeviceData = async () => {
      if (!id) return;
      const data = await getDevice(id);
      console.log(data);

      setDeviceData(data);
      // backend returns logs in descending order so already sorted
      setLatestDeviceLog(
        data.logs?.[0] || {
          timestamp: 0,
          beacon_id: '',
          beacon_name: '',
          receiver_id: '',
          receiver_name: '',
          uuid: '',
          major: 0,
          minor: 0,
          estimated_distance: 0,
          raw_data: '',
          rssi: 0,
          tx_power: null,
          estimated_proximity: '',
          mac_address: ''
        }
      );
    };
    fetchDeviceData();

    // Set up polling interval
    const interval = setInterval(fetchDeviceData, 5000);

    return () => clearInterval(interval);
  }, [id]);

  const onBack = () => {
    navigate('/');
  };

  const onEdit = () => {
    navigate(`/device/${id}/edit`);
  };

  const onQrCode = () => {
    setShowQrCode(true);
  };

  const [linkedItem, setLinkedItem] = useState<Bag | Thing | null>(null);

  const getLinkedItemInfo = async (id: string) => {
    const response = await fetch(`https://meii.minji.pack.wtf/api/items/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      if (data.bag) {
        setLinkedItem(data.bag);
      } else {
        setLinkedItem(data.thing);
      }
    }
  };

  useEffect(() => {
    if (deviceData && deviceData.linkedItemId) {
      getLinkedItemInfo(deviceData.linkedItemId);
    }
  }, [deviceData]);

  const [matchedName, setMatchedName] = useState<any | null>(null);

  useEffect(() => {
    if (deviceData && deviceData.name) {
      const matched = allKPopArtists.find((artist) => artist['Stage Name'].toLowerCase() === deviceData.name.toLowerCase());
      if (!matched) return;
      console.log(matched);

      if (!matched['Image URL']) {
        matched['Image URL'] = groupImages.find((g) => g.name === matched['Group'])?.imageUrl || '';
      }
      setMatchedName(matched);
    }
  }, [deviceData]);

  return (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} className={styles.container}>
      {/* Top Navigation */}
      <div className={styles.topNav}>
        <button onClick={onBack} className={styles.backButton}>
          <ArrowLeft size={18} className={styles.backIcon} />
          <span>Back</span>
        </button>
        <div style={{ display: 'flex', gap: '8px' }}>
          <button onClick={onQrCode} className={styles.editButton}>
            <QrCode size={18} className={styles.editIcon} />
            <span>QR</span>
          </button>
          <button onClick={onEdit} className={styles.editButton}>
            <Pencil size={18} className={styles.editIcon} />
            <span>Edit</span>
          </button>
        </div>
      </div>

      {/* QR Code Modal */}
      <QRCodeModal showQrCode={showQrCode} setShowQrCode={setShowQrCode} qrConfig={qrConfig} downloadQRCode={downloadQRCode} />

      {/* Device Header */}
      <DeviceHeader device={device} linkedItem={linkedItem} matchedName={matchedName} />

      {/* Enhanced Stats Grid */}
      <StatsGrid device={deviceData || device || undefined} latestDeviceLog={latestDeviceLog} hasLinked={!!linkedItem || !!matchedName} />

      {/* Location Details */}
      <LocationCard latestDeviceLog={latestDeviceLog} />

      {/* Timeline */}
      {deviceData && <Timeline logs={deviceData.logs || []} />}
    </motion.div>
  );
};

export default DeviceDetails;
