import { Beacon } from '../../../App';
import styles from '../../DeviceForm.module.css';

interface BeaconConfigSectionProps {
  data: Pick<Beacon, 'password' | 'connectable' | 'powerOnAlways'>;
  setData: (data: any) => void;
}

const BeaconConfigSection = ({ data, setData }: BeaconConfigSectionProps) => (
  <div className={styles.formSection}>
    <div className={styles.formGrid}>
      <div className={styles.formField}>
        <label className={`${styles.label} ${styles.required}`}>
          Password/Pin Code
          <input type="text" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} className={styles.input} placeholder="minew123" required />
        </label>
      </div>
    </div>

    <div className={styles.checkboxGroup}>
      <label className={styles.checkboxLabel}>
        <input type="checkbox" checked={data.connectable} onChange={(e) => setData({ ...data, connectable: e.target.checked })} className={styles.checkbox} />
        Connectable
      </label>
      <label className={styles.checkboxLabel}>
        <input type="checkbox" checked={data.powerOnAlways} onChange={(e) => setData({ ...data, powerOnAlways: e.target.checked })} className={styles.checkbox} />
        Power On Always
      </label>
    </div>
  </div>
);

export default BeaconConfigSection;
