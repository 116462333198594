import { ChevronDown, ChevronRight, Clock, MapPin, Plus, Radio } from 'lucide-react';
import { useEffect, useState } from 'react';

import styles from './DeviceTracker.module.css';

import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { authApi } from './Auth';
import DeviceDetails from './DeviceDetails';
import DeviceForm from './DeviceForm';
import DeviceStateIndicator from './DeviceStateIndicator';
import { useDevices } from './hooks/useDevices';
import { useUserContext } from './hooks/useUserContext';
import { allKPopArtists, groupImages } from './kpop';
import LoadingScreen from './LoadingScreen';
import TitleHeader from './TitleHeader';

// Types
export type DeviceType = 'beacon' | 'gateway';

export interface BaseDevice {
  deviceId: string;
  createdAt: string;
  updatedAt: string;
  type: DeviceType;
  state: string;
  lastSeen?: number;
  lastSeenCity?: string;
  lastSeenCountry?: string;
  name: string;
  brand: string;
  modelName: string;
  macAddress: string;
  linkedItem?: {
    id: string;
    primaryImage: string;
    name: string;
  };
}

export interface Triggers {
  motion: boolean;
  button: boolean;
}

export interface Beacon extends BaseDevice {
  type: 'beacon';
  uuid: string;
  major: number;
  minor: number;
  advInterval: number;
  connectable: boolean;
  powerOnAlways: boolean;
  txPower: number;
  txPowerUnit: string;
  triggers: Triggers;
  rssiAt1m: number;
  softwareVersion?: string;
  firmwareVersion?: string;
  hardwareVersion?: string;
  protocol: string;
  tlmFrame?: boolean;
  infoFrame?: boolean;
  password: string;
  linkedItemId?: string;
}

export interface Gateway extends BaseDevice {
  type: 'gateway';
  uploadIntervalSeconds: number;
  scanInterval: number;
  scanWindow: number;
  firmwareVersion: string;
  firmwareScanFilter: string;
  mcuModel: string;
  linkedItemId?: string;
}

export type Device = Beacon | Gateway;

interface DeviceFormProps {
  device: Device | null;
  onSave: (device: Device) => void;
  onBack: () => void;
}

export const determineImageSrc = (device: Device, imageSetting: string) => {
  if (imageSetting === 'default') {
    return `/devices/${device.modelName}.png`;
  } else if (imageSetting === 'item') {
    if (!device.linkedItemId) {
      return `/devices/${device.modelName}.png`;
    }

    if (device.linkedItem) {
      return device.linkedItem.primaryImage;
    } else {
      return `/devices/${device.modelName}.png`;
    }
  } else if (imageSetting === 'kpop') {
    const matched = allKPopArtists.find((artist) => artist['Stage Name'].toLowerCase() === device.name.toLowerCase());
    if (!matched || matched === undefined) {
      return `/devices/${device.modelName}.png`;
    }

    if (matched !== undefined && !matched['Image URL']) {
      matched['Image URL'] = groupImages.find((g) => g.name === matched['Group'])?.imageUrl || '';
    }

    if (matched !== undefined) {
      return matched['Image URL'];
    }
  }
};

interface LocationHeaderProps {
  location: string;
  count: number;
  isExpanded: boolean;
  onToggle: () => void;
}

const LocationHeader = ({ location, count, isExpanded, onToggle }: LocationHeaderProps) => {
  return (
    <button onClick={onToggle} className={styles.locationHeader} style={{ opacity: isExpanded ? '1' : '0.5' }}>
      <div className={styles.swirlsContainer}>
        <div className={`${styles.swirl} ${styles.swirlLeft}`} />
        <div className={`${styles.swirl} ${styles.swirlRight}`} />
      </div>
      <div className={styles.headerContent}>
        {isExpanded ? <ChevronDown size={16} className={`${styles.chevron} ${isExpanded ? styles.expanded : ''}`} /> : <ChevronRight size={16} className={`${styles.chevron}`} />}
        <div className={styles.dotDecorations}>
          <span className={styles.dot} />
          <span className={styles.dot} />
        </div>
        <span className={styles.locationName}>{location}</span>
        <span className={styles.locationCount}>{count}</span>
      </div>
    </button>
  );
};

export default function App() {
  const navigate = useNavigate();
  const [imageSetting, setImageSetting] = useState('default');
  const imageSettings = ['default', 'item', 'kpop'];

  const updateImageSetting = () => {
    const currentIndex = imageSettings.indexOf(imageSetting);
    const nextIndex = currentIndex + 1 >= imageSettings.length ? 0 : currentIndex + 1;
    console.log('Next index:', nextIndex);
    localStorage.setItem('imageSetting', imageSettings[nextIndex]);

    setImageSetting(imageSettings[nextIndex]);
  };

  // load imageSetting from localStorage once when the component mounts
  useEffect(() => {
    const savedSetting = localStorage.getItem('imageSetting');
    setImageSetting(savedSetting || 'default');
  }, []);
  // log imageSetting whenever it changes
  useEffect(() => {
    console.log('Image setting:', imageSetting);
  }, [imageSetting]);

  const { devices, addDevice, updateDevice, refreshDevices, toggleListSort, devicesSort, setDevicesSort } = useDevices();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentView, setCurrentView] = useState<'list' | 'add' | 'edit' | 'details'>('list');
  const [isLoading, setIsLoading] = useState(true);

  // load and update imageSetting from localStorage

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 60000);
    return () => clearInterval(timer);
  }, []);

  const { setUsername, setAvatar, setIsAuthenticating } = useUserContext();

  const getMeInfo = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/account');
    }

    const response = await fetch(`${authApi}/api/auth/me`, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      const me = await response.json();
      setUsername(me.username);
      setAvatar(me.profileUrl ?? '/default-avatar.png');
      setIsAuthenticating(false);
    }

    if (response.status === 401) {
      localStorage.removeItem('token');
      navigate('/account');
    }
  };

  useEffect(() => {
    getMeInfo();
  }, []);

  useEffect(() => {
    const lastPlayedAnimation = parseInt(localStorage.getItem('lastPlayedAnimated') || '0');

    console.log(lastPlayedAnimation);

    if (lastPlayedAnimation === 0) {
      setIsLoading(true);
      localStorage.setItem('lastPlayedAnimated', Date.now().toString());
    } else {
      const duration = Date.now() - lastPlayedAnimation;

      const twoHours = 1000 * 60 * 60 * 6;

      if (duration > twoHours) {
        setIsLoading(true);
        localStorage.setItem('lastPlayedAnimated', Date.now().toString());
      } else {
        setIsLoading(false);
        return;
      }
    }

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  // Update device grid with sequential animations
  const getDeviceDelay = (index: number) => {
    // Calculate delay for each card's bounce animation
    const baseDelay = 8; // Total animation cycle in seconds
    const itemDelay = baseDelay / (devices.length || 1);
    return `${index * itemDelay}s`;
  };

  // Add state for collapsed groups
  const [collapsedGroups, setCollapsedGroups] = useState(new Set());

  // Toggle group collapse state
  const toggleGroupCollapse = (location: unknown) => {
    setCollapsedGroups((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(location)) {
        newSet.delete(location);
      } else {
        newSet.add(location);
      }
      return newSet;
    });
  };

  return (
    <>
      <AnimatePresence>{isLoading && <LoadingScreen />}</AnimatePresence>
      <AnimatePresence>
        {!isLoading && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: isLoading ? 0 : 1 }} transition={{ duration: 1.2 }} className={styles.container}>
            <div className={styles.container}>
              {currentView === 'list' ? (
                <div className={styles.listView}>
                  <TitleHeader />

                  {devicesSort === 'location' ? (
                    // Grouped by location view
                    <div className={styles.groupedContainer}>
                      {Object.entries(
                        devices.reduce(
                          (acc, device) => {
                            const location = `${device.lastSeenCity}, ${device.lastSeenCountry}`;
                            if (!acc[location]) acc[location] = [];
                            acc[location].push(device);
                            return acc;
                          },
                          {} as Record<string, Device[]>
                        )
                      ).map(([location, groupDevices]) => (
                        <div key={location} className={styles.locationGroup}>
                          <LocationHeader location={location} count={groupDevices.length} isExpanded={!collapsedGroups.has(location)} onToggle={() => toggleGroupCollapse(location)} />
                          <AnimatePresence>
                            {!collapsedGroups.has(location) && (
                              <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} transition={{ duration: 0.3 }} className={`${styles.deviceGrid} ${styles.grouped}`}>
                                {groupDevices.map((device, index) => {
                                  let imageSrc = determineImageSrc(device, imageSetting);
                                  let deviceName = device.name;

                                  if (imageSetting === 'item' && device.linkedItemId && device.linkedItem) {
                                    deviceName = device.linkedItem.name;
                                  }

                                  return (
                                    // Your existing device card code
                                    <motion.button
                                      key={device.deviceId}
                                      initial={{
                                        opacity: 0,
                                        scale: 0.8
                                      }}
                                      animate={{
                                        opacity: 1,
                                        scale: 1
                                      }}
                                      transition={{
                                        duration: 0.4,
                                        delay: 0.1 + index * 0.1,
                                        ease: [0.23, 1.12, 0.5, 1.01]
                                      }}
                                      onClick={() => {
                                        navigate(`/device/${device.deviceId}`);
                                      }}
                                      className={`${styles.deviceCard} ${device.state === 'online' ? styles.online : styles.offline}`}
                                      style={
                                        {
                                          '--bounce-delay': getDeviceDelay(index)
                                        } as React.CSSProperties
                                      }>
                                      {device.state === 'online' && (
                                        <div className={styles.sparkleContainer}>
                                          <div className={styles.sparkle} />
                                          <div className={styles.sparkle} />
                                          <div className={styles.sparkle} />
                                          <div className={styles.sparkle} />
                                        </div>
                                      )}
                                      <div className={styles.deviceCardContent}>
                                        <div className={`${styles.deviceImageWrapper} ${imageSetting === 'default' ? styles.defaultImage : imageSetting === 'item' ? styles.itemImage : styles.kpopImage}`}>
                                          <img src={imageSrc} alt={device.name} width={48} height={48} className={`${styles.deviceImage} ${imageSetting === 'default' ? styles.defaultImage : imageSetting === 'item' ? styles.itemImage : styles.kpopImage}`} />
                                        </div>
                                        <div className={styles.deviceNameContainer}>
                                          <div className={styles.deviceNameWrapper}>
                                            <div className={styles.deviceName}>{deviceName}</div>

                                            <div className={styles.deviceLastLocation}>
                                              <MapPin className={styles.mapPinIcon} />
                                              {device.lastSeenCity + ', ' + device.lastSeenCountry}
                                            </div>
                                            <DeviceStateIndicator isOnline={device.state === 'online'} lastSeen={device.lastSeen} />
                                          </div>
                                        </div>
                                        <div className={styles.deviceModel}>
                                          {device.modelName}
                                          <span>•</span>
                                          {device.macAddress.slice(-5)}
                                        </div>
                                      </div>
                                    </motion.button>
                                  );
                                })}
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      ))}
                    </div>
                  ) : (
                    // Your existing ungrouped view
                    <div className={styles.deviceGrid}>
                      {devices.map((device, index) => {
                        let imageSrc;

                        imageSrc = determineImageSrc(device, imageSetting);

                        let deviceName = device.name;

                        if (imageSetting === 'item') {
                          if (device.linkedItemId && device.linkedItem) {
                            deviceName = device.linkedItem.name;
                          }
                        }

                        return (
                          <motion.button
                            key={device.deviceId}
                            initial={{
                              opacity: 0,
                              scale: 0.8
                            }}
                            animate={{
                              opacity: 1,
                              scale: 1
                            }}
                            transition={{
                              duration: 0.4,
                              delay: 0.1 + index * 0.1,
                              ease: [0.23, 1.12, 0.5, 1.01]
                            }}
                            onClick={() => {
                              navigate(`/device/${device.deviceId}`);
                            }}
                            className={`${styles.deviceCard} ${device.state === 'online' ? styles.online : styles.offline}`}
                            style={
                              {
                                '--bounce-delay': getDeviceDelay(index)
                              } as React.CSSProperties
                            }>
                            {device.state === 'online' && (
                              <div className={styles.sparkleContainer}>
                                <div className={styles.sparkle} />
                                <div className={styles.sparkle} />
                                <div className={styles.sparkle} />
                                <div className={styles.sparkle} />
                              </div>
                            )}
                            <div className={styles.deviceCardContent}>
                              <div className={`${styles.deviceImageWrapper} ${imageSetting === 'default' ? styles.defaultImage : imageSetting === 'item' ? styles.itemImage : styles.kpopImage}`}>
                                <img src={imageSrc} alt={device.name} width={48} height={48} className={`${styles.deviceImage} ${imageSetting === 'default' ? styles.defaultImage : imageSetting === 'item' ? styles.itemImage : styles.kpopImage}`} />
                              </div>
                              <div className={styles.deviceNameContainer}>
                                <div className={styles.deviceNameWrapper}>
                                  <div className={styles.deviceName}>{deviceName}</div>

                                  <div className={styles.deviceLastLocation}>
                                    <MapPin className={styles.mapPinIcon} />
                                    {device.lastSeenCity + ', ' + device.lastSeenCountry}
                                  </div>
                                  <DeviceStateIndicator isOnline={device.state === 'online'} lastSeen={device.lastSeen} />
                                </div>
                              </div>
                              <div className={styles.deviceModel}>
                                {device.modelName}
                                <span>•</span>
                                {device.macAddress.slice(-5)}
                              </div>
                            </div>
                          </motion.button>
                        );
                      })}
                    </div>
                  )}

                  <div className={styles.bottomBar}>
                    <div className={styles.timeDisplay} onClick={() => updateImageSetting()}>
                      <span>
                        {currentTime.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </span>
                      <span className={styles.timeDivider}>|</span>
                      <span>
                        {currentTime.toLocaleDateString([], {
                          weekday: 'short',
                          day: '2-digit',
                          month: '2-digit'
                        })}
                      </span>
                    </div>
                    <button
                      className={styles.bottomBarButton}
                      onClick={() => {
                        toggleListSort();
                      }}>
                      <Clock className={styles.clockIcon} />
                    </button>
                    <button
                      className={styles.bottomBarButton}
                      style={{ left: '5.5rem' }}
                      onClick={() => {
                        navigate('/beacons');
                      }}>
                      <Radio className={styles.clockIcon} />
                    </button>
                    <button onClick={() => navigate('/device/new')} className={styles.addDeviceButton}>
                      <Plus className={styles.addDeviceIcon} />
                    </button>
                  </div>
                </div>
              ) : currentView === 'details' ? (
                <DeviceDetails />
              ) : (
                <DeviceForm />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
