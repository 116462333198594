import { useGesture } from '@use-gesture/react';
import { Eye, EyeOff, Image } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Beacon as AppBeacon, determineImageSrc, Gateway as AppGateway } from '../../App';
import { API_BASE_URL } from '../../services/api';
import TitleHeader from '../../TitleHeader';
import styles from './BeaconMap.module.css';

// Keep all interfaces the same

// Keep all interfaces the same
interface LinkedItem {
  _id: string;
  name: string;
  size?: string;
  color?: string;
  description?: string;
  colorRgb?: string;
  brand?: string;
  care?: string;
  category?: string;
  compatibility?: string;
  condition?: string;
  connectivity?: string;
  dimensions?: string;
  material?: string;
  model?: string;
  packaging?: string;
  powerSource?: string;
  purchaseDate?: string;
  quantity?: number;
  replacementCycle?: string;
  serialNumber?: string;
  specifications?: Record<string, string>;
  storageLocation?: string;
  subCategory?: string;
  usage?: string;
  warranty?: string;
  primaryImage: string;
  id: string;
  createdAt: number;
  updatedAt: number;
  icon?: string;
  packModeHistory?: Array<{
    existingParentId: string | null;
    newParentId: string;
    timestamp: number;
    id: string;
  }>;
  parentId?: string;
  type: 'thing' | 'bag';
}

interface Beacon {
  beaconId: string;
  beaconName: string;
  beaconModel: string;
  averageRssi: number;
  estimatedDistance: number;
  sampleCount: number;
  linkedItem?: LinkedItem;
  strongest: boolean;
}

interface Gateway {
  gatewayName: string;
  gatewayModel: string;
  beacons: Beacon[];
  linkedItem?: LinkedItem;
}

interface GatewayData {
  timePeriod: number;
  gateways: Record<string, Gateway>;
}

interface Position {
  x: number;
  y: number;
}

interface LayoutCache {
  gatewayPositions: Record<string, Position>;
  beaconPositions: Record<string, Position>;
}

// Keep helper functions for creating devices
const createBeaconDevice = (beacon: Beacon): AppBeacon => ({
  deviceId: beacon.beaconId,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  type: 'beacon',
  state: 'online',
  name: beacon.beaconName,
  brand: beacon.linkedItem?.brand || 'Unknown',
  modelName: beacon.beaconModel,
  macAddress: beacon.beaconId,
  linkedItemId: beacon.linkedItem?.id,
  linkedItem: beacon.linkedItem && {
    id: beacon.linkedItem.id,
    primaryImage: beacon.linkedItem.primaryImage,
    name: beacon.linkedItem.name
  },
  uuid: beacon.beaconId,
  major: 0,
  minor: 0,
  advInterval: 0,
  connectable: true,
  powerOnAlways: true,
  txPower: 0,
  txPowerUnit: 'dBm',
  triggers: {
    motion: false,
    button: false
  },
  rssiAt1m: 0,
  protocol: 'iBeacon',
  password: ''
});

const createGatewayDevice = (gatewayId: string, gateway: Gateway): AppGateway => ({
  deviceId: gatewayId,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  type: 'gateway',
  state: 'online',
  name: gateway.gatewayName,
  brand: gateway.linkedItem?.brand || 'Unknown',
  modelName: gateway.gatewayModel,
  macAddress: gatewayId,
  linkedItemId: gateway.linkedItem?.id,
  linkedItem: gateway.linkedItem && {
    id: gateway.linkedItem.id,
    primaryImage: gateway.linkedItem.primaryImage,
    name: gateway.linkedItem.name
  },
  uploadIntervalSeconds: 0,
  scanInterval: 0,
  scanWindow: 0,
  firmwareVersion: '',
  firmwareScanFilter: '',
  mcuModel: gateway.gatewayModel
});

const getSignalStrength = (rssi: number): 'strong' | 'medium' | 'weak' => {
  if (rssi >= -45) return 'strong';
  if (rssi >= -60) return 'medium';
  return 'weak';
};

const ConnectionBeam = ({ startX, startY, endX, endY, rssi, hidden, strongest, isHighlighted, isSelectedGateway, selectedGatewayPresent }: { startX: number; startY: number; endX: number; endY: number; rssi: number; hidden?: boolean; strongest: boolean; isHighlighted?: boolean; isSelectedGateway?: boolean; selectedGatewayPresent: boolean }) => {
  const deltaX = endX - startX;
  const deltaY = endY - startY;
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
  const signalStrength = getSignalStrength(rssi);

  // Calculate opacity based on state
  let opacity = 1; // Base opacity for normal state
  if (isHighlighted) {
    opacity = 1; // Full opacity when highlighted
  } else if (hidden) {
    if (strongest) {
      opacity = 0.15; // Dim strongest connections from other gateways when a gateway is selected
    } else {
      opacity = 0; // Very dim when hidden
    }
  } else if (isSelectedGateway) {
    // For selected gateway
    opacity = strongest ? 0.6 : 0; // Show only strongest connections
  } else if (selectedGatewayPresent && !isSelectedGateway && strongest) {
    opacity = 0.15; // Dim strongest connections from other gateways when a gateway is selected
  } else if (!selectedGatewayPresent && strongest) {
    opacity = 1; // Hide non-strongest connections
  } else {
    opacity = 0; // Hide non-strongest connections
  }

  return (
    <div
      className={`${styles.connectionBeam} ${hidden ? styles.hidden : ''} ${!strongest ? styles.weakConnection : ''} ${isHighlighted ? styles.highlighted : ''}`}
      data-strength={signalStrength}
      style={{
        left: startX,
        top: startY,
        width: `${distance}px`,
        transform: `rotate(${angle}deg)`,
        transformOrigin: '0 50%',
        opacity
      }}>
      <div className={styles.connectionDots}>
        {(strongest || isHighlighted) &&
          [...Array(3)].map((_, i) => (
            <div
              key={i}
              className={styles.connectionDot}
              style={{
                animationDelay: `${i * 1}s`
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default function BeaconMapV2() {
  const [data, setData] = useState<GatewayData | null>(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [hideEmptyGateways, setHideEmptyGateways] = useState(false);
  const [imageSetting, setImageSetting] = useState('default');
  const [selectedGatewayId, setSelectedGatewayId] = useState<string | null>(null);
  const [selectedBeaconId, setSelectedBeaconId] = useState<string | null>(null);
  const imageSettings = ['default', 'item', 'kpop'];

  const layoutCache = useRef<LayoutCache>({
    gatewayPositions: {},
    beaconPositions: {}
  });

  const [{ x, y, scale }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    config: { mass: 1, tension: 350, friction: 40 }
  }));

  // Keep gesture binding and resize handlers
  const bind = useGesture(
    {
      onDrag: ({ offset: [x, y], event }) => {
        const target = event.target as HTMLElement;
        if (target.closest(`.${styles.controls}`) || target.closest('button')) return;
        api.start({ x, y });
      },
      onPinch: ({ offset: [d], event }) => {
        event.preventDefault();
        api.start({ scale: Math.max(0.5, Math.min(4, d)) });
      },
      onDoubleClick: ({ event }) => {
        const target = event.target as HTMLElement;
        if (target.closest(`.${styles.controls}`) || target.closest('button')) return;
        api.start({ x: 0, y: 0, scale: 1 });
      }
    },
    {
      drag: {
        from: () => [x.get(), y.get()],
        rubberband: true
      },
      pinch: {
        scaleBounds: { min: 0.5, max: 4 },
        rubberband: true
      }
    }
  );

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const savedSetting = localStorage.getItem('imageSetting');
    setImageSetting(savedSetting || 'default');
  }, []);

  const updateImageSetting = () => {
    const currentIndex = imageSettings.indexOf(imageSetting);
    const nextIndex = currentIndex + 1 >= imageSettings.length ? 0 : currentIndex + 1;
    localStorage.setItem('imageSetting', imageSettings[nextIndex]);
    setImageSetting(imageSettings[nextIndex]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_BASE_URL + '/devices/all-beacons-by-gateways?gateways=all&timeperiod=60', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        const newData = await response.json();
        if (newData && typeof newData === 'object' && newData.gateways) {
          setData(newData);
        } else {
          console.error('Invalid data structure received:', newData);
        }
      } catch (error) {
        console.error('Failed to fetch beacon data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, []);

  const calculateLayout = (data: GatewayData, dimensions: { width: number; height: number }) => {
    if (!data || !data.gateways || typeof data.gateways !== 'object') {
      console.error('Invalid data structure for layout calculation');
      return;
    }

    const newGatewayPositions: Record<string, Position> = {};
    const newBeaconPositions: Record<string, Position> = {};

    const gatewayEntries = Object.entries(data.gateways);
    if (!gatewayEntries.length) {
      console.log('No gateways found in data');
      return;
    }

    const visibleGateways = hideEmptyGateways ? gatewayEntries.filter(([_, gateway]) => gateway && gateway.beacons && gateway.beacons.length > 0) : gatewayEntries;

    // Calculate gateway positions
    visibleGateways.forEach(([gatewayId, gateway], index) => {
      if (!gateway) return;

      const topPadding = dimensions.height * 0.22;
      const availableHeight = 180 * (visibleGateways.length - 1);
      const spacing = availableHeight / (visibleGateways.length - 1 || 1);
      const originalIndex = visibleGateways.findIndex(([id]) => id === gatewayId);
      const horizontalOffset = dimensions.width * 0.28;
      const isEven = originalIndex % 2 === 0;
      const xPos = dimensions.width / 2 + (isEven ? -horizontalOffset : horizontalOffset);

      newGatewayPositions[gatewayId] = {
        x: xPos,
        y: topPadding + originalIndex * spacing
      };
    });

    // Find strongest gateway for each beacon
    const beaconToStrongestGateway = new Map<string, { gatewayId: string; rssi: number }>();
    visibleGateways.forEach(([gatewayId, gateway]) => {
      if (gateway && Array.isArray(gateway.beacons)) {
        gateway.beacons.forEach((beacon) => {
          const current = beaconToStrongestGateway.get(beacon.beaconId);
          if (!current || beacon.averageRssi > current.rssi) {
            beaconToStrongestGateway.set(beacon.beaconId, { gatewayId, rssi: beacon.averageRssi });
          }
        });
      }
    });

    // Calculate beacon positions around their strongest gateway
    visibleGateways.forEach(([gatewayId, gateway]) => {
      if (!gateway) return;

      // Get beacons that have this gateway as their strongest
      const strongBeacons = gateway.beacons.filter((beacon) => beaconToStrongestGateway.get(beacon.beaconId)?.gatewayId === gatewayId);

      strongBeacons.forEach((beacon, beaconIndex) => {
        const gatewayPos = newGatewayPositions[gatewayId];
        if (!gatewayPos) return;

        const rssi = beacon.averageRssi;
        const normalizedRssi = (rssi + 45) / 15;
        const strengthFactor = Math.pow(1 - normalizedRssi, 2) * 0.28;

        const minRadius = 70;
        const maxRadius = Math.min(dimensions.width, dimensions.height) * 0.3;
        const radius = minRadius + (maxRadius - minRadius) * strengthFactor;

        const angle = (beaconIndex * 2 * Math.PI) / strongBeacons.length;
        newBeaconPositions[beacon.beaconId] = {
          x: gatewayPos.x + radius * Math.cos(angle),
          y: gatewayPos.y + radius * Math.sin(angle)
        };
      });
    });

    layoutCache.current = {
      gatewayPositions: newGatewayPositions,
      beaconPositions: newBeaconPositions
    };
  };

  useEffect(() => {
    if (data) {
      calculateLayout(data, dimensions);
    }
  }, [data, dimensions.width, dimensions.height, hideEmptyGateways]);

  const getGatewayPosition = (gatewayId: string) => {
    const pos = layoutCache.current.gatewayPositions[gatewayId];
    if (!pos) return { x: 0, y: 0 };
    return pos;
  };

  const getBeaconPosition = (beaconId: string, isSelected: boolean) => {
    const pos = layoutCache.current.beaconPositions[beaconId];
    if (!pos) return { x: 0, y: 0 };

    // If a gateway is selected, arrange its beacons in a semi-circle
    if (selectedGatewayId && data?.gateways[selectedGatewayId]) {
      const gateway = data.gateways[selectedGatewayId];
      const gatewayPos = layoutCache.current.gatewayPositions[selectedGatewayId];
      if (!gatewayPos) return pos;

      // Get only the strongest beacons for this gateway
      const strongestBeacons = gateway.beacons.filter((b) => b.strongest);

      // Find index of this beacon in the strongest beacons array
      const beaconIndex = strongestBeacons.findIndex((b) => b.beaconId === beaconId);
      if (beaconIndex === -1) return pos; // Beacon not among strongest for this gateway

      const totalBeacons = strongestBeacons.length;

      // Calculate angle in the semi-circle (0 to 180 degrees)
      const angleStep = Math.PI / (totalBeacons - 1 || 1);
      const angle = angleStep * beaconIndex;

      // Fixed radius for semi-circle
      const radius = 180;

      // Calculate position on semi-circle
      // Using negative cosine to make the semi-circle appear above the gateway
      return {
        x: gatewayPos.x + radius * Math.sin(angle),
        y: gatewayPos.y - radius * Math.cos(angle)
      };
    }

    return pos;
  };

  const handleGatewayClick = (gatewayId: string) => {
    setSelectedGatewayId(selectedGatewayId === gatewayId ? null : gatewayId);
    setSelectedBeaconId(null);
  };

  const handleBeaconClick = (beaconId: string) => {
    setSelectedBeaconId(selectedBeaconId === beaconId ? null : beaconId);
    setSelectedGatewayId(null);
  };

  const renderDeviceImage = (device: AppBeacon | AppGateway, mode: string) => {
    if (mode === 'default') {
      return <img src={`/devices/${device.modelName}.png`} alt={device.name} className={styles.deviceImage} />;
    } else if (mode === 'item' && device.linkedItem) {
      return <img src={device.linkedItem.primaryImage} alt={device.linkedItem.name} className={styles.deviceImage} />;
    } else {
      const imageSrc = determineImageSrc(device, mode);
      return <img src={imageSrc} alt={device.name} className={styles.deviceImage} />;
    }
  };

  const renderBeaconConnections = (gatewayId: string, gatewayPos: Position, beacons: Beacon[]) => {
    const gatewayCenterPos = {
      x: gatewayPos.x + 24,
      y: gatewayPos.y + 24
    };

    return beacons.map((beacon) => {
      const beaconPos = getBeaconPosition(beacon.beaconId || '', false);
      const beaconCenterPos = {
        x: beaconPos.x + 20,
        y: beaconPos.y + 20
      };

      const isHighlighted = selectedBeaconId === beacon.beaconId;
      const isHidden = selectedBeaconId !== null && !isHighlighted;
      const isSelectedGateway = selectedGatewayId === gatewayId;

      return <ConnectionBeam key={beacon.beaconId} startX={gatewayCenterPos.x} startY={gatewayCenterPos.y} endX={beaconCenterPos.x} endY={beaconCenterPos.y} rssi={beacon.averageRssi} hidden={isHidden} strongest={beacon.strongest} isHighlighted={isHighlighted} isSelectedGateway={isSelectedGateway} selectedGatewayPresent={selectedGatewayId ? true : false} />;
    });
  };

  if (!data || !data.gateways) {
    return (
      <div className={styles.container}>
        <TitleHeader />
        <div className={styles.mapContainer}>Loading...</div>
      </div>
    );
  }

  const gatewayEntries = Object.entries(data.gateways);
  const visibleGateways = hideEmptyGateways ? gatewayEntries.filter(([_, gateway]) => gateway && gateway.beacons && gateway.beacons.length > 0) : gatewayEntries;

  // Get unique beacons and find which gateways can see the selected beacon
  const uniqueBeacons = new Map<string, Beacon>();
  const gatewaysWithSelectedBeacon = new Set<string>();
  visibleGateways.forEach(([gatewayId, gateway]) => {
    gateway.beacons.forEach((beacon) => {
      const existing = uniqueBeacons.get(beacon.beaconId);
      if (!existing || beacon.strongest) {
        uniqueBeacons.set(beacon.beaconId, beacon);
      }
      if (beacon.beaconId === selectedBeaconId) {
        gatewaysWithSelectedBeacon.add(gatewayId);
      }
    });
  });

  return (
    <div className={styles.container}>
      <TitleHeader />
      <div {...bind()} className={styles.mapContainer}>
        <div className={styles.controls}>
          <button className={styles.controlButton} onClick={() => setHideEmptyGateways(!hideEmptyGateways)}>
            {hideEmptyGateways ? <EyeOff size={16} /> : <Eye size={16} />}
            {hideEmptyGateways ? 'Show Empty' : 'Hide Empty'}
          </button>
          <button className={styles.controlButton} onClick={updateImageSetting}>
            <Image size={16} />
            {imageSetting === 'default' ? 'Device' : imageSetting === 'item' ? 'Item' : 'K-pop'}
          </button>
        </div>

        <animated.div
          className={styles.mapContent}
          style={{
            transform: scale.to((s) => `scale(${s})`),
            x,
            y
          }}>
          {/* Render gateways and their connections */}
          {visibleGateways.map(([gatewayId, gateway]) => {
            const pos = getGatewayPosition(gatewayId);
            const isEmpty = gateway.beacons.length === 0;
            const gatewayDevice = createGatewayDevice(gatewayId, gateway);
            const isSelected = selectedGatewayId === gatewayId;
            const isConnectedToSelectedBeacon = gatewaysWithSelectedBeacon.has(gatewayId);
            const isUnselected = (selectedGatewayId !== null && !isSelected) || (selectedBeaconId !== null && !isConnectedToSelectedBeacon);

            return (
              <div key={gatewayId}>
                {/* Render connections to all beacons this gateway can see */}
                {renderBeaconConnections(gatewayId, pos, gateway.beacons)}
                <div className={`${styles.gateway} ${isEmpty && hideEmptyGateways ? styles.hidden : ''} ${isSelected ? styles.selected : ''} ${isUnselected ? styles.dimmed : ''}`} style={{ left: pos.x, top: pos.y }} onClick={() => handleGatewayClick(gatewayId)}>
                  <div className={styles.gatewayIcon}>{renderDeviceImage(gatewayDevice, imageSetting)}</div>
                  <div className={styles.gatewayName}>{imageSetting === 'item' && gateway.linkedItem ? gateway.linkedItem.name : gateway.gatewayName}</div>
                </div>
              </div>
            );
          })}

          {/* Render unique beacons */}
          {Array.from(uniqueBeacons.entries()).map(([beaconId, beacon]) => {
            const beaconPos = getBeaconPosition(beaconId || '', false);
            const deviceInfo = createBeaconDevice(beacon);
            const isSelected = selectedBeaconId === beaconId;
            const isUnselected = selectedBeaconId !== null && !isSelected;

            // When a gateway is selected, check if this beacon has a strong connection to it
            let hasStrongConnection = true;
            if (selectedGatewayId) {
              const selectedGateway = data.gateways[selectedGatewayId];
              const beaconInGateway = selectedGateway?.beacons.find((b) => b.beaconId === beaconId);
              hasStrongConnection = beaconInGateway?.strongest || false;
            }

            const isConnectedToSelectedGateway = selectedGatewayId ? data.gateways[selectedGatewayId]?.beacons.some((b) => b.beaconId === beaconId) : true;
            const shouldShow = !selectedGatewayId || isConnectedToSelectedGateway;

            if (!shouldShow) return null;

            return (
              <div
                key={beaconId}
                className={`${styles.beacon} ${isSelected ? styles.selected : ''} ${isUnselected || (selectedGatewayId && !hasStrongConnection) ? styles.dimmed : ''}`}
                style={{
                  left: beaconPos.x,
                  top: beaconPos.y
                }}
                onClick={() => handleBeaconClick(beaconId)}>
                <div className={styles.beaconIcon}>{renderDeviceImage(deviceInfo, imageSetting)}</div>
                <div className={styles.beaconName}>{imageSetting === 'item' && beacon.linkedItem ? beacon.linkedItem.name : beacon.beaconName}</div>
              </div>
            );
          })}
        </animated.div>
      </div>
    </div>
  );
}
