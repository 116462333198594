import { Bluetooth, Network } from 'lucide-react';
import { DeviceType } from '../../App';
import styles from '../DeviceForm.module.css';

interface DeviceTypeSelectorProps {
  selectedType: DeviceType;
  onTypeChange: (type: DeviceType) => void;
}

const DeviceTypeSelector = ({ selectedType, onTypeChange }: DeviceTypeSelectorProps) => (
  <div className={styles.formSection}>
    <div className={styles.deviceTypeSelector}>
      <button type="button" className={`${styles.deviceTypeButton} ${selectedType === 'beacon' ? styles.selected : ''}`} onClick={() => onTypeChange('beacon')}>
        <span className={styles.deviceTypeIcon}>
          <Bluetooth size={24} />
        </span>
        <span>Beacon</span>
      </button>
      <button type="button" className={`${styles.deviceTypeButton} ${selectedType === 'gateway' ? styles.selected : ''}`} onClick={() => onTypeChange('gateway')}>
        <span className={styles.deviceTypeIcon}>
          <Network size={24} />
        </span>
        <span>Gateway</span>
      </button>
    </div>
  </div>
);

export default DeviceTypeSelector;
