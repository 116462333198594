import { motion } from 'framer-motion';
import { Clock, Globe, Radio, Signal } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { BeaconLog, DeviceWithLogs } from '..';
import styles from '../styles/StatsGrid.module.css';

interface StatsGridProps {
  device?: DeviceWithLogs;
  latestDeviceLog?: BeaconLog;
  hasLinked: boolean;
}

export const StatsGrid: React.FC<StatsGridProps> = ({ device, latestDeviceLog, hasLinked }) => {
  const getLastSeenTime = (timestamp: number) => {
    const lastSeen = new Date(Date.UTC(new Date(timestamp).getUTCFullYear(), new Date(timestamp).getUTCMonth(), new Date(timestamp).getUTCDate(), new Date(timestamp).getUTCHours(), new Date(timestamp).getUTCMinutes(), new Date(timestamp).getUTCSeconds()));
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - lastSeen.getTime()) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
  };

  const getAverageRSSI = (logs?: BeaconLog[]) => {
    if (!logs?.length) return 'N/A';

    // Filter out logs with invalid RSSI values
    const validLogs = logs.filter((log) => typeof log.rssi === 'number' && !isNaN(log.rssi));

    if (!validLogs.length) return 'N/A';

    const sum = validLogs.reduce((acc, log) => acc + log.rssi, 0);
    const average = sum / validLogs.length;

    return `${average.toFixed(1)} dBm`;
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!latestDeviceLog) return;
    if (latestDeviceLog.ip_country) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [latestDeviceLog]);

  const shimmerAnimation = {
    initial: { backgroundPosition: '-200% center' },
    animate: { backgroundPosition: '200% center' },
    transition: { duration: 2, repeat: Infinity, ease: 'linear' }
  };

  const loadingVariants = {
    initial: { opacity: 0.5 },
    animate: { opacity: 1 },
    transition: { duration: 0.8, repeat: Infinity, repeatType: 'reverse' }
  };

  return (
    <div className={styles.statsGridMobile}>
      <motion.div className={`${styles.statCard} ${styles.locationStat} ${isLoading ? styles.isLoading : ''}`} whileTap={{ scale: 0.98 }}>
        <div className={styles.statIcon}>
          <div className={styles.sparkleContainer}>
            <div className={styles.sparkle}></div>
            <div className={styles.sparkle}></div>
            <div className={styles.sparkle}></div>
            <div className={styles.sparkle}></div>
          </div>

          <Globe className={styles.statIconBlue} />
        </div>
        <div className={styles.statContent}>
          <div className={styles.statLabel}>Location</div>
          <motion.div className={`${styles.statValue} ${!latestDeviceLog?.location_name ? styles.placeholder : ''}`} variants={shimmerAnimation} initial="initial" animate="animate">
            {device?.lastSeenCity + ', ' + device?.lastSeenCountry || 'Unknown Location'}
          </motion.div>
        </div>
        {isLoading && (
          <div className={styles.loadingSparkles}>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
          </div>
        )}
      </motion.div>
      {true && (
        <motion.div className={`${styles.statCard} ${styles.statusStat} ${isLoading ? styles.isLoading : ''}`} whileTap={{ scale: 0.98 }}>
          <div className={styles.statIcon}>
            <div className={styles.sparkleContainer}>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
            </div>

            <Signal className={styles.statIconGreen} />
          </div>
          <div className={styles.statContent}>
            <div className={styles.statLabel}>Status</div>
            <motion.div className={styles.statValue} variants={loadingVariants} initial="initial" animate="animate">
              <span className={`${styles.statusDot} ${device?.state === 'online' ? styles.online : device?.state === 'offline' ? styles.offline : styles.unknown}`} />
              {device?.state === 'online' ? 'Online' : device?.state === 'offline' ? 'Offline' : 'Unknown'}
            </motion.div>
          </div>
          {isLoading && (
            <div className={styles.loadingSparkles}>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
            </div>
          )}
        </motion.div>
      )}
      <motion.div className={`${styles.statCard} ${styles.timeStat} ${isLoading ? styles.isLoading : ''}`} whileTap={{ scale: 0.98 }}>
        <div className={styles.statIcon}>
          <div className={styles.sparkleContainer}>
            <div className={styles.sparkle}></div>
            <div className={styles.sparkle}></div>
            <div className={styles.sparkle}></div>
            <div className={styles.sparkle}></div>
          </div>

          <Clock className={styles.statIconPurple} />
        </div>
        <div className={styles.statContent}>
          <div className={styles.statLabel}>Last Seen</div>
          <motion.div className={`${styles.statValue} ${!device?.lastSeen ? styles.placeholder : ''}`} variants={shimmerAnimation} initial="initial" animate="animate">
            {device?.lastSeen ? (
              <>
                {getLastSeenTime(device.lastSeen)} <span className={styles.rxName}>{latestDeviceLog?.receiver_name || ''}</span>
              </>
            ) : (
              'Never'
            )}
          </motion.div>
        </div>
        {isLoading && (
          <div className={styles.loadingSparkles}>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
          </div>
        )}
      </motion.div>

      {true && (
        <motion.div className={`${styles.statCard} ${styles.signalStat} ${isLoading ? styles.isLoading : ''}`} whileTap={{ scale: 0.98 }}>
          <div className={styles.statIcon}>
            <div className={styles.sparkleContainer}>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
            </div>

            <Radio className={styles.statIconOrange} />
          </div>
          <div className={styles.statContent}>
            <div className={styles.statLabel}>Avg Signal</div>
            <motion.div className={`${styles.statValue} ${device?.logs ? styles.placeholder : ''}`} variants={shimmerAnimation} initial="initial" animate="animate">
              {device?.logs ? getAverageRSSI(device.logs) : 'No Data'}
            </motion.div>
          </div>
          {isLoading && (
            <div className={styles.loadingSparkles}>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
              <div className={styles.loadingSparkle}></div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};
