import { Beacon } from '../../../App';
import styles from '../../DeviceForm.module.css';

interface BeaconFramesConfigProps {
  data: Pick<Beacon, 'tlmFrame' | 'infoFrame'>;
  setData: (data: any) => void;
}

const BeaconFramesConfig = ({ data, setData }: BeaconFramesConfigProps) => (
  <div className={styles.formSection}>
    <div className={styles.checkboxGroup}>
      <label className={styles.checkboxLabel}>
        <input type="checkbox" checked={true} disabled className={styles.checkbox} />
        iBeacon
      </label>
      <label className={styles.checkboxLabel}>
        <input type="checkbox" checked={data.tlmFrame || false} onChange={(e) => setData({ ...data, tlmFrame: e.target.checked })} className={styles.checkbox} />
        TLM
      </label>
      <label className={styles.checkboxLabel}>
        <input type="checkbox" checked={data.infoFrame || false} onChange={(e) => setData({ ...data, infoFrame: e.target.checked })} className={styles.checkbox} />
        DeviceInfo
      </label>
    </div>
  </div>
);

export default BeaconFramesConfig;
