import { useGesture } from '@use-gesture/react';
import { Eye, EyeOff, Image } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Beacon as AppBeacon, determineImageSrc, Gateway as AppGateway } from '../App';
import { API_BASE_URL } from '../services/api';
import TitleHeader from '../TitleHeader';
import styles from './BeaconMap.module.css';

interface LinkedItem {
  _id: string;
  name: string;
  size?: string;
  color?: string;
  description?: string;
  colorRgb?: string;
  brand?: string;
  care?: string;
  category?: string;
  compatibility?: string;
  condition?: string;
  connectivity?: string;
  dimensions?: string;
  material?: string;
  model?: string;
  packaging?: string;
  powerSource?: string;
  purchaseDate?: string;
  quantity?: number;
  replacementCycle?: string;
  serialNumber?: string;
  specifications?: Record<string, string>;
  storageLocation?: string;
  subCategory?: string;
  usage?: string;
  warranty?: string;
  primaryImage: string;
  id: string;
  createdAt: number;
  updatedAt: number;
  icon?: string;
  packModeHistory?: Array<{
    existingParentId: string | null;
    newParentId: string;
    timestamp: number;
    id: string;
  }>;
  parentId?: string;
  type: 'thing' | 'bag';
}

interface Beacon {
  beaconId: string;
  beaconName: string;
  beaconModel: string;
  averageRssi: number;
  estimatedDistance: number;
  sampleCount: number;
  linkedItemId?: string;
  linkedItem?: LinkedItem;
}

interface Gateway {
  gatewayName: string;
  gatewayModel: string;
  beacons: Beacon[];
  linkedItemId?: string;
  linkedItem?: LinkedItem;
}

interface GatewayData {
  timePeriod: number;
  gateways: Record<string, Gateway>;
}

interface Position {
  x: number;
  y: number;
}

interface LayoutCache {
  gatewayPositions: Record<string, Position>;
  beaconPositions: Record<string, Record<string, Position>>;
}

const createBeaconDevice = (beacon: Beacon): AppBeacon => ({
  deviceId: beacon.beaconId,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  type: 'beacon',
  state: 'online',
  name: beacon.beaconName,
  brand: beacon.linkedItem?.brand || 'Unknown',
  modelName: beacon.beaconModel,
  macAddress: beacon.beaconId,
  linkedItemId: beacon.linkedItemId,
  linkedItem: beacon.linkedItem && {
    id: beacon.linkedItem.id,
    primaryImage: beacon.linkedItem.primaryImage,
    name: beacon.linkedItem.name
  },
  uuid: beacon.beaconId,
  major: 0,
  minor: 0,
  advInterval: 0,
  connectable: true,
  powerOnAlways: true,
  txPower: 0,
  txPowerUnit: 'dBm',
  triggers: {
    motion: false,
    button: false
  },
  rssiAt1m: 0,
  protocol: 'iBeacon',
  password: ''
});

const createGatewayDevice = (gatewayId: string, gateway: Gateway): AppGateway => ({
  deviceId: gatewayId,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  type: 'gateway',
  state: 'online',
  name: gateway.gatewayName,
  brand: gateway.linkedItem?.brand || 'Unknown',
  modelName: gateway.gatewayModel,
  macAddress: gatewayId,
  linkedItemId: gateway.linkedItemId,
  linkedItem: gateway.linkedItem && {
    id: gateway.linkedItem.id,
    primaryImage: gateway.linkedItem.primaryImage,
    name: gateway.linkedItem.name
  },
  uploadIntervalSeconds: 0,
  scanInterval: 0,
  scanWindow: 0,
  firmwareVersion: '',
  firmwareScanFilter: '',
  mcuModel: gateway.gatewayModel
});

const getSignalStrength = (rssi: number): 'strong' | 'medium' | 'weak' => {
  if (rssi >= -45) return 'strong';
  if (rssi >= -60) return 'medium';
  return 'weak';
};

const ConnectionBeam = ({ startX, startY, endX, endY, rssi, hidden }: { startX: number; startY: number; endX: number; endY: number; rssi: number; hidden?: boolean }) => {
  const deltaX = endX - startX;
  const deltaY = endY - startY;
  const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
  const signalStrength = getSignalStrength(rssi);

  return (
    <div
      className={`${styles.connectionBeam} ${hidden ? styles.hidden : ''}`}
      data-strength={signalStrength}
      style={{
        left: startX,
        top: startY,
        width: `${distance}px`,
        transform: `rotate(${angle}deg)`,
        transformOrigin: '0 50%'
      }}>
      <div className={styles.connectionDots}>
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className={styles.connectionDot}
            style={{
              animationDelay: `${i * 1}s`
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default function BeaconMap() {
  const [data, setData] = useState<GatewayData | null>(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [hideEmptyGateways, setHideEmptyGateways] = useState(false);
  const [imageSetting, setImageSetting] = useState('default');
  const [selectedGatewayId, setSelectedGatewayId] = useState<string | null>(null);
  const imageSettings = ['default', 'item', 'kpop'];

  // Cache for layout positions
  const layoutCache = useRef<LayoutCache>({
    gatewayPositions: {},
    beaconPositions: {}
  });

  // Pan and zoom state
  const [{ x, y, scale }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    config: { mass: 1, tension: 350, friction: 40 }
  }));

  // Bind gestures
  const bind = useGesture(
    {
      onDrag: ({ offset: [x, y], event }) => {
        const target = event.target as HTMLElement;
        if (target.closest(`.${styles.controls}`) || target.closest('button')) return;
        api.start({ x, y });
      },
      onPinch: ({ offset: [d], event }) => {
        event.preventDefault();
        api.start({ scale: Math.max(0.5, Math.min(4, d)) });
      },
      onDoubleClick: ({ event }) => {
        const target = event.target as HTMLElement;
        if (target.closest(`.${styles.controls}`) || target.closest('button')) return;
        api.start({ x: 0, y: 0, scale: 1 });
      }
    },
    {
      drag: {
        from: () => [x.get(), y.get()],
        rubberband: true
      },
      pinch: {
        scaleBounds: { min: 0.5, max: 4 },
        rubberband: true
      }
    }
  );

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const savedSetting = localStorage.getItem('imageSetting');
    setImageSetting(savedSetting || 'default');
  }, []);

  const updateImageSetting = () => {
    const currentIndex = imageSettings.indexOf(imageSetting);
    const nextIndex = currentIndex + 1 >= imageSettings.length ? 0 : currentIndex + 1;
    localStorage.setItem('imageSetting', imageSettings[nextIndex]);
    setImageSetting(imageSettings[nextIndex]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_BASE_URL + '/devices/beacons-by-gateways?gateways=all&timeperiod=60', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        const newData = await response.json();
        setData(newData);
      } catch (error) {
        console.error('Failed to fetch beacon data:', error);
      }
    };

    // Initial fetch
    fetchData();

    // Decrease interval to 2 seconds for more frequent updates
    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, []);

  // Calculate and cache layout positions
  const calculateLayout = (data: GatewayData, dimensions: { width: number; height: number }) => {
    const newGatewayPositions: Record<string, Position> = {};
    const newBeaconPositions: Record<string, Record<string, Position>> = {};

    const gatewayEntries = Object.entries(data.gateways);
    const visibleGateways = hideEmptyGateways ? gatewayEntries.filter(([_, gateway]) => gateway.beacons.length > 0) : gatewayEntries;
    const totalGateways = gatewayEntries.length;

    visibleGateways.forEach(([gatewayId, gateway], index) => {
      const isMobile = false;

      // Calculate gateway position
      if (true) {
        const topPadding = dimensions.height * 0.22; // Reduced from 0.28 to give more space
        const bottomPadding = dimensions.height * 0.18;
        const availableHeight = 160 * (visibleGateways.length - 1);

        // Calculate spacing based on total possible gateways, not just visible ones
        const spacing = availableHeight / (visibleGateways.length - 1 || 1);

        // Find the original index of this gateway in the full list
        const originalIndex = visibleGateways.findIndex(([id]) => id === gatewayId);

        const horizontalOffset = dimensions.width * 0.28;
        const isEven = originalIndex % 2 === 0;
        const xPos = dimensions.width / 2 + (isEven ? -horizontalOffset : horizontalOffset);

        newGatewayPositions[gatewayId] = {
          x: xPos,
          y: topPadding + originalIndex * spacing // Use original index for consistent spacing
        };
      } else {
        const radius = Math.min(dimensions.width, dimensions.height) * 0.4;
        const angle = (index * 2 * Math.PI) / visibleGateways.length;
        const centerX = dimensions.width / 2;
        const centerY = dimensions.height / 2;

        newGatewayPositions[gatewayId] = {
          x: centerX + radius * Math.cos(angle),
          y: centerY + radius * Math.sin(angle)
        };
      }

      // Calculate beacon positions with signal strength-based distance
      newBeaconPositions[gatewayId] = {};
      gateway.beacons.forEach((beacon, beaconIndex) => {
        const gatewayPos = newGatewayPositions[gatewayId];
        const rssi = beacon.averageRssi;

        // Enhanced non-linear mapping for -45 to -60 range
        const normalizedRssi = (rssi + 45) / 15; // Normalize to 0-1 range
        const strengthFactor = Math.pow(1 - normalizedRssi, 2) * 0.28; // Exponential curve, max 0.5

        // Base radius range
        const minRadius = isMobile ? 80 : 80;
        const maxRadius = Math.min(dimensions.width, dimensions.height) * (isMobile ? 0.2 : 0.3);

        // Calculate radius based on signal strength (stronger signal = shorter distance)
        const radius = minRadius + (maxRadius - minRadius) * strengthFactor;

        const angle = (beaconIndex * 2 * Math.PI) / gateway.beacons.length;
        newBeaconPositions[gatewayId][beacon.beaconId] = {
          x: gatewayPos.x + radius * Math.cos(angle),
          y: gatewayPos.y + radius * Math.sin(angle)
        };
      });
    });

    layoutCache.current = {
      gatewayPositions: newGatewayPositions,
      beaconPositions: newBeaconPositions
    };
  };

  // Update layout when data changes
  useEffect(() => {
    if (data) {
      calculateLayout(data, dimensions);
    }
  }, [data, dimensions.width, dimensions.height, hideEmptyGateways]);

  const getGatewayPosition = (gatewayId: string) => {
    const pos = layoutCache.current.gatewayPositions[gatewayId];
    if (!pos) return { x: 0, y: 0 };
    return pos; // Always return cached position, no special handling for selected gateway
  };
  const getBeaconPosition = (gatewayId: string, beaconId: string, isSelected: boolean) => {
    const basePos = layoutCache.current.beaconPositions[gatewayId]?.[beaconId];
    if (!basePos) return { x: 0, y: 0 };

    if (isSelected) {
      const gatewayPos = getGatewayPosition(gatewayId);
      const gateway = data?.gateways[gatewayId];
      if (!gateway) return basePos;

      // Find index of this beacon in the gateway's beacons array
      const beaconIndex = gateway.beacons.findIndex((b) => b.beaconId === beaconId);
      const totalBeacons = gateway.beacons.length;

      // Calculate angle in the semi-circle (0 to 180 degrees)
      const angleStep = Math.PI / (totalBeacons - 1 || 1);
      const angle = angleStep * beaconIndex;

      // Fixed radius for semi-circle
      const radius = 180;

      // Calculate position on semi-circle
      // Using negative cosine to make the semi-circle appear above the gateway
      return {
        x: gatewayPos.x + radius * Math.sin(angle),
        y: gatewayPos.y - radius * Math.cos(angle)
      };
    }

    return basePos;
  };

  const handleGatewayClick = (gatewayId: string) => {
    setSelectedGatewayId(selectedGatewayId === gatewayId ? null : gatewayId);
  };

  const renderDeviceImage = (device: AppBeacon | AppGateway, mode: string) => {
    if (mode === 'default') {
      return <img src={`/devices/${device.modelName}.png`} alt={device.name} className={styles.deviceImage} />;
    } else if (mode === 'item' && device.linkedItem) {
      return <img src={device.linkedItem.primaryImage} alt={device.linkedItem.name} className={styles.deviceImage} />;
    } else {
      const imageSrc = determineImageSrc(device, mode);
      return <img src={imageSrc} alt={device.name} className={styles.deviceImage} />;
    }
  };

  const renderBeaconConnections = (gatewayId: string, gatewayPos: Position, beacons: Beacon[], isSelected: boolean) => {
    const gatewayCenterPos = {
      x: gatewayPos.x + 24,
      y: gatewayPos.y + 24
    };

    return beacons.map((beacon) => {
      const beaconPos = getBeaconPosition(gatewayId, beacon.beaconId, isSelected);
      const beaconCenterPos = {
        x: beaconPos.x + 20,
        y: beaconPos.y + 20
      };

      return <ConnectionBeam key={beacon.beaconId} startX={gatewayCenterPos.x} startY={gatewayCenterPos.y} endX={beaconCenterPos.x} endY={beaconCenterPos.y} rssi={beacon.averageRssi} hidden={selectedGatewayId !== null && selectedGatewayId !== gatewayId} />;
    });
  };

  if (!data) {
    return (
      <div className={styles.container}>
        <TitleHeader />
        <div className={styles.mapContainer}>Loading...</div>
      </div>
    );
  }

  const gatewayEntries = Object.entries(data.gateways);
  const visibleGateways = hideEmptyGateways ? gatewayEntries.filter(([_, gateway]) => gateway.beacons.length > 0) : gatewayEntries;

  return (
    <div className={styles.container}>
      <TitleHeader />
      <div {...bind()} className={styles.mapContainer}>
        <div className={styles.controls}>
          <button className={styles.controlButton} onClick={() => setHideEmptyGateways(!hideEmptyGateways)}>
            {hideEmptyGateways ? <EyeOff size={16} /> : <Eye size={16} />}
            {hideEmptyGateways ? 'Show Empty' : 'Hide Empty'}
          </button>
          <button className={styles.controlButton} onClick={updateImageSetting}>
            <Image size={16} />
            {imageSetting === 'default' ? 'Device' : imageSetting === 'item' ? 'Item' : 'K-pop'}
          </button>
        </div>

        <animated.div
          className={styles.mapContent}
          style={{
            transform: scale.to((s) => `scale(${s})`),
            x,
            y
          }}>
          {visibleGateways.map(([gatewayId, gateway]) => {
            const pos = getGatewayPosition(gatewayId);
            const isEmpty = gateway.beacons.length === 0;
            const gatewayDevice = createGatewayDevice(gatewayId, gateway);
            const isSelected = selectedGatewayId === gatewayId;
            const isUnselected = selectedGatewayId !== null && !isSelected;

            return (
              <div key={gatewayId}>
                {renderBeaconConnections(gatewayId, pos, gateway.beacons, isSelected)}
                <div className={`${styles.gateway} ${isEmpty && hideEmptyGateways ? styles.hidden : ''} ${isSelected ? styles.selected : ''} ${isUnselected ? styles.dimmed : ''}`} style={{ left: pos.x, top: pos.y }} onClick={() => handleGatewayClick(gatewayId)}>
                  <div className={styles.gatewayIcon}>{renderDeviceImage(gatewayDevice, imageSetting)}</div>
                  <div className={styles.gatewayName}>{imageSetting === 'item' && gateway.linkedItem ? gateway.linkedItem.name : gateway.gatewayName}</div>
                </div>

                {gateway.beacons.map((beacon) => {
                  const beaconPos = getBeaconPosition(gatewayId, beacon.beaconId, isSelected);
                  const deviceInfo = createBeaconDevice(beacon);

                  return (
                    <div
                      key={beacon.beaconId}
                      className={`${styles.beacon} ${selectedGatewayId !== null && selectedGatewayId !== gatewayId ? styles.dimmed : ''}`}
                      style={{
                        left: beaconPos.x,
                        top: beaconPos.y
                      }}>
                      <div className={styles.beaconIcon}>{renderDeviceImage(deviceInfo, imageSetting)}</div>
                      <div className={styles.beaconName}>{imageSetting === 'item' && beacon.linkedItem ? beacon.linkedItem.name : beacon.beaconName}</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </animated.div>
      </div>
    </div>
  );
}
