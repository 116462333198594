import { motion } from 'framer-motion';
import { MapPin } from 'lucide-react';
import React from 'react';
import { BeaconLog } from '..';
import styles from '../styles/LocationCard.module.css';

interface LocationCardProps {
  latestDeviceLog: BeaconLog;
}

export const LocationCard: React.FC<LocationCardProps> = ({ latestDeviceLog }) => {
  const formatCoordinates = (lat?: number, long?: number) => {
    if (!lat || !long) return 'Not Available';
    return `${Number(lat).toFixed(6)}, ${Number(long).toFixed(6)}`;
  };

  if (!latestDeviceLog.location_latitude || !latestDeviceLog.location_longitude) return null;

  return (
    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.3 }} className={styles.locationCard}>
      <h2 className={styles.locationTitle}>
        <MapPin className={styles.locationIcon} />
        Location Details
      </h2>
      <div className={styles.locationGrid}>
        <div className={styles.locationDetail}>
          <div className={styles.detailLabel}>Coordinates</div>
          <div className={styles.detailValue}>{formatCoordinates(latestDeviceLog.location_latitude, latestDeviceLog.location_longitude)}</div>
        </div>
        <div className={styles.locationDetail}>
          <div className={styles.detailLabel}>Country</div>
          <div className={styles.detailValue}>{latestDeviceLog.location_country || 'Unknown'}</div>
        </div>
        <div className={styles.mapPlaceholder}>
          <span>Map View</span>
        </div>
      </div>
    </motion.div>
  );
};
