import { Beacon } from '../../../App';
import styles from '../../DeviceForm.module.css';

interface BeaconTriggersSectionProps {
  data: Pick<Beacon, 'triggers'>;
  setData: (data: any) => void;
}

const BeaconTriggersSection = ({ data, setData }: BeaconTriggersSectionProps) => (
  <div className={styles.formSection}>
    <div className={styles.checkboxGroup}>
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          checked={data.triggers.motion}
          onChange={(e) =>
            setData({
              ...data,
              triggers: {
                ...data.triggers,
                motion: e.target.checked
              }
            })
          }
          className={styles.checkbox}
        />
        Motion Trigger
      </label>
      <label className={styles.checkboxLabel}>
        <input
          type="checkbox"
          checked={data.triggers.button}
          onChange={(e) =>
            setData({
              ...data,
              triggers: {
                ...data.triggers,
                button: e.target.checked
              }
            })
          }
          className={styles.checkbox}
        />
        Button Trigger
      </label>
    </div>
  </div>
);

export default BeaconTriggersSection;
