import { AnimatePresence, motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import styles from '../styles/QRCodeModal.module.css';

interface QRCodeModalProps {
  showQrCode: boolean;
  setShowQrCode: (show: boolean) => void;
  qrConfig: {
    size: number;
    level: 'L' | 'M' | 'Q' | 'H';
    bgColor: string;
    fgColor: string;
    includeMargin: boolean;
  };
  downloadQRCode: () => void;
}

export const QRCodeModal: React.FC<QRCodeModalProps> = ({ showQrCode, setShowQrCode, qrConfig, downloadQRCode }) => {
  return (
    <AnimatePresence>
      {showQrCode && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={styles.modalOverlay} onClick={() => setShowQrCode(false)}>
          <motion.div initial={{ scale: 0.9 }} animate={{ scale: 1 }} exit={{ scale: 0.9 }} className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <h2>QR Code</h2>
            <div className={styles.qrCodeContainer}>
              <QRCodeSVG id="device-qr-code" value={window.location.href} size={qrConfig.size} level={qrConfig.level} bgColor={qrConfig.bgColor} fgColor={qrConfig.fgColor} includeMargin={qrConfig.includeMargin} />
            </div>
            <button onClick={downloadQRCode} className={styles.downloadButton}>
              Download PNG
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
