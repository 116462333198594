import { Beacon, Gateway } from './App';

export const availableBeaconBrands = ['Feasycom', 'Minew', 'GhostYu', 'M5'];

export const defaultBeacon: Beacon = {
  deviceId: '',
  createdAt: '',
  updatedAt: '',
  type: 'beacon',
  uuid: '2188865d852345cf9c55729270d8be54',
  major: 2000,
  minor: 1,
  name: '',
  brand: '',
  modelName: '',
  advInterval: 900,
  connectable: true,
  powerOnAlways: false,
  password: 'minew123',
  txPower: 4,
  txPowerUnit: 'dBm',
  triggers: {
    motion: false,
    button: false
  },
  rssiAt1m: -3,
  macAddress: '',
  softwareVersion: '',
  firmwareVersion: '',
  hardwareVersion: '',
  protocol: 'iBeacon',
  state: ''
};

export const availableBeaconConfigs = [
  {
    name: 'Feasycom',
    beaconModels: [
      {
        ...defaultBeacon,
        modelName: 'BP106',
        advInterval: 1000,
        txPower: 4,
        rssiAt1m: -3,
        tlmFrame: true,
        infoFrame: true,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: false,
          button: true
        },
        password: '113377'
      },
      {
        modelName: 'BP105N',
        advInterval: 1300,
        txPower: 0,
        rssiAt1m: -55,
        tlmFrame: true,
        infoFrame: false,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: false,
          button: true
        },
        password: '113377'
      },
      {
        modelName: 'BP108',
        advInterval: 800,
        txPower: 2.5,
        rssiAt1m: -59,
        tlmFrame: false,
        infoFrame: false,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: false,
          button: true
        },
        password: '113377'
      }
    ]
  },
  {
    name: 'Minew',
    beaconModels: [
      {
        modelName: 'E7',
        advInterval: 900,
        txPower: 0,
        rssiAt1m: -59,
        tlmFrame: true,
        infoFrame: true,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: true,
          button: true
        },
        password: 'bbbb1337'
      },
      {
        ...defaultBeacon,
        modelName: 'S1',
        advInterval: 1000,
        txPower: 4,
        rssiAt1m: -3,
        tlmFrame: true,
        infoFrame: true,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: false,
          button: true
        },
        password: 'bbbb1337'
      },
      {
        ...defaultBeacon,
        modelName: 'i6',
        advInterval: 1300,
        txPower: 0,
        rssiAt1m: -59,
        tlmFrame: true,
        infoFrame: true,
        powerOnAlways: false,
        major: 4000,
        connectable: true,
        triggers: {
          motion: false,
          button: false
        },
        password: 'bbbb1337'
      },
      {
        ...defaultBeacon,
        modelName: 'E8',
        advInterval: 1000,
        major: 2800,
        txPower: 0,
        rssiAt1m: -59,
        tlmFrame: true,
        infoFrame: true,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: true,
          button: false
        },
        password: 'bbbb1337'
      }
    ]
  },
  {
    name: 'GhostYu',
    beaconModels: [
      {
        modelName: 'iBc41'
      }
    ]
  },
  {
    name: 'M5',
    beaconModels: []
  },
  {
    name: 'Blue Charm Beacons',
    beaconModels: [
      {
        ...defaultBeacon,
        modelName: 'BC05',
        password: 'bbbb1337',
        txPower: 4,
        rssiAt1m: -59,
        triggers: {
          motion: true,
          button: true
        },
        tlmFrame: false,
        infoFrame: false,
        powerOnAlways: false
      },
      {
        ...defaultBeacon,
        modelName: 'BC021',
        password: 'bbbb1337',
        txPower: 4,
        rssiAt1m: -59,
        triggers: {
          motion: true,
          button: true
        },
        tlmFrame: false,
        infoFrame: false,
        powerOnAlways: false
      },
      {
        ...defaultBeacon,
        modelName: 'BC011',
        password: 'bbbb1337',
        txPower: 4,
        rssiAt1m: -59,
        triggers: {
          motion: false,
          button: true
        },
        tlmFrame: false,
        infoFrame: false,
        powerOnAlways: false
      }
    ]
  },
  {
    name: 'KKM',
    beaconModels: [
      {
        ...defaultBeacon,
        modelName: 'K21',
        advInterval: 1000,
        txPower: 4,
        rssiAt1m: -59,
        tlmFrame: false,
        infoFrame: false,
        powerOnAlways: false,
        connectable: true,
        triggers: {
          motion: true,
          button: true
        }
      }
    ]
  }
];

export const defaultGateway: Gateway = {
  deviceId: '',
  createdAt: '',
  updatedAt: '',
  type: 'gateway',
  name: '',
  brand: '',
  modelName: '',
  uploadIntervalSeconds: 1,
  scanInterval: 150,
  scanWindow: 150,
  macAddress: '',
  firmwareVersion: '',
  firmwareScanFilter: '',
  mcuModel: '',
  state: ''
};

export const availableGatewayBrands = ['M5'];

export const availableGatewayConfigs = [
  {
    name: 'M5',
    gatewayModels: [
      {
        ...defaultGateway,
        modelName: 'ATOM-Lite',

        uploadIntervalSeconds: 5,
        scanInterval: 150,
        scanWindow: 150,

        firmwareVersion: 'v1.0.0',
        firmwareScanFilter: 'iBeacon/2188865d852345cf9c55729270d8be54',
        mcuModel: 'ESP32-PICO'
      }
    ]
  }
];
