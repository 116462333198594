import { Gateway } from '../../../App';
import styles from '../../DeviceForm.module.css';

interface ScanningSectionProps {
  data: Pick<Gateway, 'uploadIntervalSeconds' | 'scanInterval' | 'scanWindow' | 'firmwareVersion' | 'firmwareScanFilter'>;
  setData: (data: any) => void;
}

const ScanningSection = ({ data, setData }: ScanningSectionProps) => (
  <div className={styles.formSection}>
    <div className={styles.formGrid}>
      <div className={styles.formField}>
        <label className={`${styles.label} ${styles.required}`}>
          Upload Interval (seconds)
          <input type="number" value={data.uploadIntervalSeconds} onChange={(e) => setData({ ...data, uploadIntervalSeconds: parseInt(e.target.value) })} className={styles.input} min="1" required />
        </label>
      </div>
      <div className={styles.formField}>
        <label className={`${styles.label} ${styles.required}`}>
          Scan Interval
          <input type="number" value={data.scanInterval} onChange={(e) => setData({ ...data, scanInterval: parseInt(e.target.value) })} className={styles.input} min="1" required />
        </label>
      </div>
      <div className={styles.formField}>
        <label className={`${styles.label} ${styles.required}`}>
          Scan Window
          <input type="number" value={data.scanWindow} onChange={(e) => setData({ ...data, scanWindow: parseInt(e.target.value) })} className={styles.input} min="1" required />
        </label>
      </div>
      <div className={styles.formField}>
        <label className={styles.label}>
          Firmware Version
          <input type="text" value={data.firmwareVersion} onChange={(e) => setData({ ...data, firmwareVersion: e.target.value })} className={styles.input} placeholder="2.3.12" />
        </label>
      </div>
      <div className={styles.formField}>
        <label className={styles.label}>
          Firmware Scan Filter
          <input type="text" value={data.firmwareScanFilter} onChange={(e) => setData({ ...data, firmwareScanFilter: e.target.value })} className={styles.input} placeholder="iBeacon/uuid" />
        </label>
      </div>
    </div>
  </div>
);

export default ScanningSection;
