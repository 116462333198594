import { Beacon } from '../../../App';
import styles from '../../DeviceForm.module.css';

interface BeaconVersionSectionProps {
  data: Pick<Beacon, 'softwareVersion' | 'firmwareVersion' | 'hardwareVersion'>;
  setData: (data: any) => void;
}

const BeaconVersionSection = ({ data, setData }: BeaconVersionSectionProps) => (
  <div className={styles.formSection}>
    <div className={styles.formGrid}>
      <div className={styles.formField}>
        <label className={styles.label}>
          Software Version
          <input type="text" value={data.softwareVersion || ''} onChange={(e) => setData({ ...data, softwareVersion: e.target.value })} className={styles.input} placeholder="nRF52-SDK13.0" />
        </label>
      </div>
      <div className={styles.formField}>
        <label className={styles.label}>
          Firmware Version
          <input type="text" value={data.firmwareVersion || ''} onChange={(e) => setData({ ...data, firmwareVersion: e.target.value })} className={styles.input} placeholder="2.3.12" />
        </label>
      </div>
      <div className={styles.formField}>
        <label className={styles.label}>
          Hardware Version
          <input type="text" value={data.hardwareVersion || ''} onChange={(e) => setData({ ...data, hardwareVersion: e.target.value })} className={styles.input} placeholder="MS71SF6_V1.0.0" />
        </label>
      </div>
    </div>
  </div>
);

export default BeaconVersionSection;
