import { motion } from 'framer-motion';
import { ArrowUpRight, BluetoothSearching, Network } from 'lucide-react';
import React from 'react';
import { Device } from '../../App';
import { Bag, Thing } from '../../types';
import styles from '../styles/DeviceHeader.module.css';

interface DeviceHeaderProps {
  device: Device | null;
  linkedItem: Bag | Thing | null;
  matchedName: any | null;
}

export const DeviceHeader: React.FC<DeviceHeaderProps> = ({ device, linkedItem, matchedName }) => {
  if (!device) return null;

  const handleLinkedItemClick = () => {
    if (!linkedItem) return;

    // Determine if it's a bag or thing based on the presence of specific properties
    const itemType = linkedItem.icon === '🎒' ? 'bag' : 'thing';
    const url = `https://pack.wtf/${itemType}/${linkedItem.id}`;
    window.open(url, '_blank');
  };

  return (
    <motion.div initial={{ scale: 0.95 }} animate={{ scale: 1 }} className={styles.deviceHeader}>
      <div className={styles.sparkleContainer}>
        <div className={styles.sparkle} />
        <div className={styles.sparkle} />
        <div className={styles.sparkle} />
        <div className={styles.sparkle} />
      </div>
      <div className={styles.deviceHeaderContent}>
        <motion.div initial={{ rotate: -10 }} animate={{ rotate: 0 }} className={styles.deviceImageContainer}>
          <div className={styles.deviceImageWrapper}>
            <img src={`/devices/${device.modelName}.png`} alt={device.name} className={styles.deviceImage} />
          </div>
          <motion.div
            animate={{
              scale: device.state === 'online' ? [1, 1.2, 1] : 1
            }}
            transition={{
              repeat: Infinity,
              duration: 2,
              ease: 'easeInOut'
            }}
            className={`${styles.statusIndicator} ${device.state === 'online' ? styles.online : styles.offline}`}
          />
        </motion.div>
        <div className={styles.deviceInfo}>
          <motion.div className={styles.deviceTypeBadge} initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }}>
            {device.type === 'beacon' ? (
              <>
                <BluetoothSearching size={14} />
                Beacon
              </>
            ) : (
              <>
                <Network size={14} />
                Gateway
              </>
            )}
          </motion.div>
          <motion.h1 initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }} className={styles.deviceName}>
            {device.name}
          </motion.h1>
          <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.1 }} className={styles.deviceMac}>
            {device.macAddress}
          </motion.div>
          <motion.div initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.2 }} className={styles.deviceModel}>
            {device.modelName}
          </motion.div>
        </div>
      </div>

      <div
        style={{
          marginTop: linkedItem || matchedName ? '28px' : '0px'
        }}>
        {linkedItem && (
          <motion.div
            className={`${styles.metaCard} ${styles.linkedItemMeta}`}
            whileTap={{ scale: 0.98 }}
            onClick={handleLinkedItemClick}
            style={{
              padding: '0.4rem 0.5rem',
              background: 'linear-gradient(135deg,rgba(255, 255, 255, 0.95) 0%,rgba(246, 247, 248, 0.95) 100%)',
              cursor: 'pointer'
            }}>
            <div className={styles.metaIcon}>
              <img
                style={{
                  width: '55px',
                  height: '55px',
                  objectFit: 'cover',
                  borderRadius: '0.7rem'
                }}
                src={linkedItem.primaryImage}
                className={styles.statIconOrange}
                alt={linkedItem.name}
              />
            </div>
            <div className={styles.statContent}>
              <div
                className={styles.metaLabel}
                style={{
                  fontFamily: 'Geist Regular',
                  fontSize: '10px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                Linked Item{' '}
                <ArrowUpRight
                  style={{
                    width: '10px',
                    height: '10px',
                    marginLeft: '4px'
                  }}
                />
              </div>
              <motion.div className={`${styles.metaValue}`} initial="initial" animate="animate" style={{ fontSize: '14.4px' }}>
                {linkedItem.name}
              </motion.div>
            </div>
          </motion.div>
        )}

        {matchedName && (
          <motion.div
            className={`${styles.metaCard} ${styles.linkedNameMeta}`}
            whileTap={{ scale: 0.98 }}
            style={{
              marginBottom: '0px',
              marginTop: linkedItem ? '8px' : '0px',
              padding: '0.4rem 0.5rem',
              background: 'linear-gradient(135deg,rgba(255, 255, 255, 0.95) 0%,rgba(246, 247, 248, 0.95) 100%)'
            }}>
            <div className={styles.metaIcon}>
              <img
                style={{
                  width: '55px',
                  height: '55px',
                  objectFit: 'cover',
                  borderRadius: '0.7rem'
                }}
                src={matchedName['Image URL']}
                className={styles.statIconOrange}
                alt={matchedName['Stage Name']}
              />
            </div>
            <div className={styles.statContent}>
              <div
                className={styles.metaLabel}
                style={{
                  fontFamily: 'Geist Regular',
                  fontSize: '10px'
                }}>
                {matchedName['Group']}{' '}
                <span
                  style={{
                    color: 'lightgrey',
                    marginLeft: '2px',
                    marginRight: '2px'
                  }}>
                  -
                </span>{' '}
                {matchedName['Company']}
              </div>
              <motion.div className={`${styles.metaValue}`} initial="initial" animate="animate" style={{ fontSize: '14.4px' }}>
                {matchedName['Stage Name']}{' '}
                <span
                  style={{
                    color: 'lightgrey',
                    marginLeft: '0px',
                    marginRight: '0px'
                  }}>
                  /
                </span>
                {matchedName['K Stage Name']}
              </motion.div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};
