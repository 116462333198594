import { AnimatePresence, motion } from 'framer-motion';
import { Bell, Bluetooth, ChevronLeft, CogIcon, Cpu, FileCog, Focus, Info, LucideBaggageClaim, Radio, Save, Scan } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Beacon, Device, DeviceType, Gateway } from '../App';
import { defaultBeacon, defaultGateway } from '../const';
import { useDevices } from '../hooks/useDevices';
import { BeaconBasicSection, BeaconConfigSection, BeaconFramesConfig, BeaconTriggersSection, BeaconVersionSection, DeviceTypeSelector, GatewayBasicSection, GatewayDeviceInfoSection, IBeaconSection, LinkedItemSection, ScanningSection } from './components';
import styles from './DeviceForm.module.css';

const DeviceForm = () => {
  const { addDevice, updateDevice, getDevice, devices } = useDevices();
  const navigateRouter = useNavigate();
  const [device, setDevice] = useState<Device | null>(null);
  const [deviceType, setDeviceType] = useState<DeviceType>(device?.type || 'beacon');
  const [formData, setFormData] = useState<Device>(device || (deviceType === 'beacon' ? defaultBeacon : defaultGateway));
  const { id } = useParams<{ id: string }>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [editingDevice, setEditingDevice] = useState<Device | null>(null);

  useEffect(() => {
    const fetchDeviceData = async () => {
      if (!id) {
        setIsLoaded(true);
        setEditingDevice(null);
        return;
      }
      const data = await getDevice(id);
      setDevice(data);
      setDeviceType(data.type);
      setEditingDevice(data);
      setFormData(data);
      setIsLoaded(true);
    };
    fetchDeviceData();
  }, [id]);

  const onBack = () => {
    navigateRouter(-1);
  };

  const onSave = async (device: Device) => {
    try {
      if (editingDevice) {
        const { deviceId, createdAt, updatedAt, ...deviceData } = device;
        await updateDevice(editingDevice.deviceId, deviceData);
        navigateRouter(`/device/${device.deviceId}`);
      } else {
        await addDevice(device);
        navigateRouter('/');
      }
    } catch (err) {
      console.error('Failed to save device:', err);
    }
  };

  const [currentStep, setCurrentStep] = useState(device ? 1 : 0);
  const [direction, setDirection] = useState(0);

  const steps =
    deviceType === 'beacon'
      ? [
          { title: 'Type', icon: Bluetooth },
          { title: 'Basic Info', icon: Info },
          { title: 'Item Link', icon: LucideBaggageClaim },
          { title: 'Config', icon: FileCog },
          { title: 'Frames', icon: Focus },
          { title: 'iBeacon', icon: Radio },
          { title: 'Triggers', icon: Bell },
          { title: 'Version Info', icon: Cpu }
        ]
      : [
          { title: 'Choose Type', icon: Bluetooth },
          { title: 'Basic Info', icon: Info },
          { title: 'Hardware', icon: CogIcon },
          { title: 'Scanning', icon: Scan },
          { title: 'Item Link', icon: LucideBaggageClaim }
        ];

  const activeSteps = steps;

  const renderStepContent = (step: number): JSX.Element | null => {
    if (deviceType === 'beacon' && formData.type === 'beacon') {
      const beaconData = formData as Beacon;
      switch (step) {
        case 0:
          return <DeviceTypeSelector selectedType={deviceType} onTypeChange={handleTypeChange} />;
        case 1:
          return <BeaconBasicSection data={beaconData} setData={setFormData} allDevices={devices} />;
        case 2:
          return <LinkedItemSection data={beaconData} setData={setFormData} />;
        case 3:
          return <BeaconConfigSection data={beaconData} setData={setFormData} />;
        case 4:
          return <BeaconFramesConfig data={beaconData} setData={setFormData} />;
        case 5:
          return <IBeaconSection data={beaconData} setData={setFormData} allDevices={devices} />;
        case 6:
          return <BeaconTriggersSection data={beaconData} setData={setFormData} />;
        case 7:
          return <BeaconVersionSection data={beaconData} setData={setFormData} />;
        default:
          return null;
      }
    } else if (deviceType === 'gateway' && formData.type === 'gateway') {
      const gatewayData = formData as Gateway;
      switch (step) {
        case 0:
          return <DeviceTypeSelector selectedType={deviceType} onTypeChange={handleTypeChange} />;
        case 1:
          return <GatewayBasicSection data={gatewayData} setData={setFormData} allDevices={devices} />;
        case 2:
          return <GatewayDeviceInfoSection data={gatewayData} setData={setFormData} />;
        case 3:
          return <ScanningSection data={gatewayData} setData={setFormData} />;
        case 4:
          return <LinkedItemSection data={gatewayData} setData={setFormData} />;
        default:
          return null;
      }
    }
    return null;
  };

  const handleTypeChange = (type: DeviceType) => {
    setDeviceType(type);
    setFormData(type === 'beacon' ? defaultBeacon : defaultGateway);
  };

  const navigate = (delta: number) => {
    const newStep = currentStep + delta;
    if (newStep >= 0 && newStep < activeSteps.length) {
      setDirection(delta);
      setCurrentStep(newStep);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await onSave(formData);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const StepIcon = activeSteps[currentStep].icon;

  return (
    <div className={styles.formContainer}>
      <div className={styles.formHeader}>
        <button onClick={onBack} className={styles.backButton}>
          <ChevronLeft size={20} />
          <span>Back</span>
        </button>
        <h1 className={styles.formTitle}>{device ? 'Edit Device' : 'Add Device'}</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={styles.formContent}>
          <AnimatePresence mode="wait" custom={direction}>
            {isLoaded && (
              <motion.div key={currentStep} custom={direction} initial={{ opacity: 0, y: direction > 0 ? 20 : -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: direction > 0 ? -20 : 20 }} transition={{ duration: 0.2 }} className={styles.formStep}>
                {renderStepContent(currentStep)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className={styles.formNavigation}>
          <div className={styles.tabContainer}>
            {activeSteps.map((step, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setDirection(index - currentStep);
                  setCurrentStep(index);
                }}
                className={`${styles.tabButton} ${index === currentStep ? styles.active : ''}`}>
                <div className={styles.tabIcon}>
                  <step.icon size={20} />
                </div>
                <span className={styles.tabLabel}>{step.title}</span>
                {index === currentStep && <motion.div className={styles.tabHighlight} layoutId="tabHighlight" />}
              </button>
            ))}
          </div>

          <button type="submit" disabled={isSubmitting} className={`${styles.saveButton} ${isSubmitting ? styles.isLoading : ''}`}>
            <div className={styles.sparkleContainer}>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
              <div className={styles.sparkle}></div>
            </div>
            <Save size={20} />
            <span>{device ? 'Update Device' : 'Save Device'}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default DeviceForm;
