import { useEffect, useState } from 'react';
import { Beacon, Device } from '../../../App';
import styles from '../../DeviceForm.module.css';

interface IBeaconSectionProps {
  data: Pick<Beacon, 'protocol' | 'uuid' | 'advInterval' | 'major' | 'minor' | 'txPowerUnit' | 'txPower' | 'rssiAt1m' | 'deviceId'>;
  setData: (data: any) => void;
  allDevices?: Device[];
}

const IBeaconSection = ({ data, setData, allDevices = [] }: IBeaconSectionProps) => {
  const [majorError, setMajorError] = useState<string>('');
  const [minorError, setMinorError] = useState<string>('');

  const validateBeaconValues = (major: number, minor: number) => {
    const existingBeacon = allDevices.find(
      (device) =>
        device.type === 'beacon' &&
        device.deviceId !== data.deviceId && // Exclude current device when editing
        (device as Beacon).major === major &&
        (device as Beacon).minor === minor
    );

    if (existingBeacon) {
      return `Beacon with Major ${major} and Minor ${minor} already exists`;
    }
    return '';
  };

  const handleMajorChange = (value: number) => {
    const error = validateBeaconValues(value, data.minor);
    setMajorError(error);
    setData({ ...data, major: value });
  };

  const handleMinorChange = (value: number) => {
    const error = validateBeaconValues(data.major, value);
    setMinorError(error);
    setData({ ...data, minor: value });
  };

  // Validate on initial load and when allDevices changes
  useEffect(() => {
    const error = validateBeaconValues(data.major, data.minor);
    setMajorError(error);
    setMinorError(error);
  }, [allDevices]);

  return (
    <div className={styles.formSection} style={{ overflowY: 'scroll' }}>
      <div className={styles.formGrid}>
        <div className={styles.formField}>
          <label className={styles.label}>
            Protocol
            <input type="text" value={data.protocol} className={`${styles.input} ${styles.readOnly}`} readOnly />
          </label>
        </div>
        <div className={styles.formField}>
          <label className={styles.label}>
            UUID
            <input type="text" value={data.uuid} className={`${styles.input} ${styles.readOnly}`} readOnly />
          </label>
        </div>
        <div className={styles.formField}>
          <label className={`${styles.label} ${styles.required}`}>
            Advertisement Interval (ms)
            <input type="number" value={data.advInterval} onChange={(e) => setData({ ...data, advInterval: parseInt(e.target.value) })} className={styles.input} min="100" max="10000" required />
          </label>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={styles.formField}>
            <label className={`${styles.label} ${styles.required}`}>
              Major
              <input type="number" value={data.major} onChange={(e) => handleMajorChange(parseInt(e.target.value))} className={`${styles.input} ${majorError ? styles.error : ''}`} min="0" max="65535" style={{ width: '75%' }} required />
              {majorError && <div className={styles.errorText}>{majorError}</div>}
            </label>
          </div>
          <div className={styles.formField}>
            <label className={`${styles.label} ${styles.required}`}>
              Minor
              <input type="number" value={data.minor} onChange={(e) => handleMinorChange(parseInt(e.target.value))} className={`${styles.input} ${minorError ? styles.error : ''}`} min="0" max="65535" style={{ width: '75%' }} required />
              {minorError && <div className={styles.errorText}>{minorError}</div>}
            </label>
          </div>
        </div>
      </div>
      <div className={styles.formGrid}>
        <div className={styles.formField}>
          <label className={styles.label}>
            TX Power Unit
            <input type="text" value={data.txPowerUnit} className={`${styles.input} ${styles.readOnly}`} readOnly />
          </label>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={styles.formField}>
            <label className={`${styles.label} ${styles.required}`}>
              TX Power
              <input type="number" value={data.txPower} onChange={(e) => setData({ ...data, txPower: parseInt(e.target.value) })} className={styles.input} style={{ width: '75%' }} min="-40" max="4" required />
            </label>
          </div>
          <div className={styles.formField}>
            <label className={`${styles.label} ${styles.required}`}>
              RSSI at 1m
              <input type="number" value={data.rssiAt1m} onChange={(e) => setData({ ...data, rssiAt1m: parseInt(e.target.value) })} className={styles.input} min="-100" style={{ width: '75%' }} max="0" required />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IBeaconSection;
