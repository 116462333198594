import { AnimatePresence, motion } from 'framer-motion';
import { Activity, ChevronDown, Navigation, Radio, Signal, SignalHigh, Wifi } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { BeaconLog } from '.';
import styles from './styles/Timeline.module.css';

interface TimelineProps {
  logs: BeaconLog[];
}

const Timeline: React.FC<TimelineProps> = ({ logs }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedView, setSelectedView] = useState<'timeline' | 'chart'>('timeline');

  const getRssiColor = (rssi: number) => {
    const strength = Math.min(100, Math.max(0, (rssi + 100) * 2));
    if (strength > 70) return '#10b981';
    if (strength > 40) return '#f59e0b';
    return '#ef4444';
  };

  const getRssiGradient = (rssi: number) => {
    const strength = Math.min(100, Math.max(0, (rssi + 100) * 2));

    if (strength > 70) {
      return `linear-gradient(135deg, #10b981 0%, #34d399 50%, #6ee7b7 100%)`;
    } else if (strength > 40) {
      return `linear-gradient(135deg, #f59e0b 0%, #fbbf24 50%, #fcd34d 100%)`;
    } else {
      return `linear-gradient(135deg, #ef4444 0%, #f87171 50%, #fca5a5 100%)`;
    }
  };

  const getRssiGradientSmooth = (rssi: number) => {
    const strength = Math.min(100, Math.max(0, (rssi + 100) * 2));

    if (strength > 80) {
      return `linear-gradient(135deg, #059669 0%, #10b981 50%, #34d399 100%)`;
    } else if (strength > 60) {
      return `linear-gradient(135deg, #10b981 0%, #34d399 40%, #fbbf24 100%)`;
    } else if (strength > 40) {
      return `linear-gradient(135deg, #f59e0b 0%, #fbbf24 50%, #fcd34d 100%)`;
    } else if (strength > 20) {
      return `linear-gradient(135deg, #f59e0b 0%, #ef4444 60%, #f87171 100%)`;
    } else {
      return `linear-gradient(135deg, #dc2626 0%, #ef4444 50%, #f87171 100%)`;
    }
  };

  const getRssiLabel = (rssi: number): string => {
    if (rssi < -80) return 'Poor';
    if (rssi < -60) return 'Fair';
    if (rssi < -40) return 'Good';
    if (rssi < -20) return 'Excellent';
    return 'Very Weak';
  };

  const getSignalQuality = (rssi: number): number => {
    return Math.min(100, Math.max(0, (rssi + 100) * 2));
  };

  const latestLogs = logs.slice(0, 20);
  const currentSignal = latestLogs[0]?.rssi || -100;

  const convertToLocalTime = (utcDateString: string) => {
    const date = new Date(utcDateString + 'Z'); // Append 'Z' to indicate UTC time
    return date.toLocaleTimeString('en-UK'); // Convert to local time string
  };

  const [visibleLogs, setVisibleLogs] = useState<BeaconLog[]>([]);
  const [visibleThirtyLogs, setVisibleThirtyLogs] = useState<BeaconLog[]>([]);

  function filterAndSortLogs(logs: BeaconLog[]) {
    const logsByPeriod = new Map();

    logs.forEach((log) => {
      const timestamp = new Date(log.timestamp);
      const minutes = timestamp.getMinutes();
      const seconds = timestamp.getSeconds();
      const periodSeconds = seconds < 30 ? '00' : '30';
      const periodKey = `${timestamp.toISOString().slice(0, 16)}:${periodSeconds}`;

      if (!logsByPeriod.has(periodKey) || new Date(logsByPeriod.get(periodKey).timestamp) < timestamp) {
        logsByPeriod.set(periodKey, log);
      }
    });

    return Array.from(logsByPeriod.values()).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  const filterLogs = () => {
    const filteredLogs = filterAndSortLogs(logs);
    const filteredThirtyLogs = filterAndSortLogs(logs);
    setVisibleThirtyLogs(filteredThirtyLogs);
    setVisibleLogs(filteredLogs);
  };

  useEffect(() => {
    filterLogs();
  }, [logs]);

  return (
    <motion.div className={styles.timelineCard}>
      <button className={styles.timelineHeader} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={styles.timelineTitle}>
          <Activity className={styles.timelineIcon} />
          <h2>Activity Timeline</h2>
        </div>
        <motion.div animate={{ rotate: isExpanded ? 180 : 0 }} className={styles.expandIcon}>
          <ChevronDown size={20} />
        </motion.div>
      </button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: 'auto', opacity: 1 }} exit={{ height: 0, opacity: 0 }} className={styles.timelineContent}>
            {/* View Toggle */}
            <div className={styles.viewToggle}>
              <button className={`${styles.viewButton} ${selectedView === 'timeline' ? styles.viewButtonActive : ''}`} onClick={() => setSelectedView('timeline')}>
                <Activity size={16} />
                Timeline
              </button>
              <button className={`${styles.viewButton} ${selectedView === 'chart' ? styles.viewButtonActive : ''}`} onClick={() => setSelectedView('chart')}>
                <Signal size={16} />
                Signal Chart
              </button>
            </div>

            {selectedView === 'timeline' ? (
              <div className={styles.timeline}>
                {visibleLogs.map((log, index) => (
                  <motion.div key={`${log.timestamp}-${log.rssi}-${log.estimated_distance}-${index}`} initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: index * 0.1 }} className={styles.timelineItem}>
                    <div className={styles.timelineDot} style={{ borderColor: getRssiColor(log.rssi), background: getRssiGradient(log.rssi), color: 'white' }}>
                      <SignalHigh size={18} />
                    </div>
                    <div className={styles.timelineItemContent}>
                      <div className={styles.timelineItemHeader}>
                        {/* @ts-ignore */}
                        <span className={styles.timelineTime}>{convertToLocalTime(log.timestamp)}</span>
                        <span className={styles.proximityBadge} style={{ backgroundColor: `${getRssiColor(log.rssi)}15`, color: getRssiColor(log.rssi) }}>
                          {getRssiLabel(log.rssi)}
                        </span>
                      </div>

                      <div className={styles.timelineDetails}>
                        <div className={styles.signalMetrics}>
                          <div className={styles.signalWrapper}>
                            <div className={styles.signalLabel}>
                              <Radio size={14} />
                              Signal Strength
                            </div>
                            <div className={styles.signalBarContainer}>
                              <div className={styles.signalValue}>{log.rssi} dBm</div>
                              <div className={styles.signalBar}>
                                <div className={styles.signalBarFill} style={{ width: `${Math.min(100, Math.max(0, (log.rssi + 100) * 2))}%`, backgroundColor: getRssiColor(log.rssi) }} />
                              </div>
                            </div>
                          </div>

                          <div className={styles.distanceWrapper}>
                            <div className={styles.distanceLabel}>
                              <Navigation size={14} />
                              Distance
                            </div>
                            <div className={styles.distanceValue}>{log.estimated_distance.toFixed(2)}m</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            ) : (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={styles.signalChart}>
                {/* Signal Strength Chart */}
                <div className={styles.mobileChartView}>
                  {/* Current Signal Indicator */}
                  <div className={styles.currentSignal}>
                    <div className={styles.signalRing}>
                      <motion.div className={styles.signalIndicator} style={{ background: getRssiColor(currentSignal) }} initial={{ scale: 0 }} animate={{ scale: 1 }}>
                        <Radio size={24} className={styles.signalIcon} />
                      </motion.div>
                      <div className={styles.signalValue}>
                        <div className={styles.signalStrength}>{currentSignal} dBm</div>
                        <div className={styles.signalLabel}>{getRssiLabel(currentSignal)}</div>
                      </div>
                    </div>
                  </div>

                  {/* Signal History */}
                  <div className={styles.signalHistory}>
                    <div className={styles.historyTitle}>
                      <Activity size={16} />
                      Signal History
                    </div>
                    <div className={styles.historyGrid}>
                      {visibleThirtyLogs.map((log, index) => (
                        <motion.div key={`${log.timestamp}-${log.rssi}-${log.estimated_distance}-${index}`} className={styles.historyItem} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1 }}>
                          {/* @ts-ignore */}
                          <div className={styles.historyTime}>{convertToLocalTime(log.timestamp)}</div>
                          <div className={styles.historyBar} style={{ '--strength': `${Math.min(100, Math.max(0, (log.rssi + 100) * 2))}%`, '--color': getRssiColor(log.rssi) } as React.CSSProperties} />
                          <div className={styles.historyValue}>{log.rssi} dBm</div>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}

            {/* Signal Quality Summary */}
            <div className={styles.signalSummary}>
              <div className={styles.summaryCard}>
                <Wifi className={styles.summaryIcon} />
                <div className={styles.summaryInfo}>
                  <div className={styles.summaryLabel}>Average Signal</div>
                  <div className={styles.summaryValue}>{(logs.reduce((acc, log) => acc + log.rssi, 0) / logs.length).toFixed(1)} dBm</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Timeline;
